.Advantage {
  overflow: hidden;

  & &-container {
    max-width: 913px;
  }

  &-swiper {
    max-width: 210px;
    overflow: visible;
  }

  &-wrap {
    margin-bottom: 24px;
  }

  @media (min-width: 767px) {
    &-swiper {
      max-width: none;
    }
  }

  @media (min-width: $large) {
    & &-button {
      padding: 17px 57px 16px 56px;
      font-size: 14px;
    }

    &-wrap,
    .block-editor-block-list__layout,
    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px 24px;

      .wp-block {
        margin: 0;
      }
    }

    &-swiper {
      max-width: none;

      &.swiper-container-pointer-events .swiper-wrapper {
        transform: none !important;
      }
    }
  }
}

.wp-block .Advantage-wrap {
  display: block;
}
