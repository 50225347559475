@import '../molecules/CardColors';

.ColorCards {
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 320px;
    margin: 0 auto;

    .block-editor-block-list__layout {
      display: flex;
      gap: 20px;
    }
  }

  .block-editor-block-list__block {
    margin: 0;
  }

  @media (min-width: $tablet) {
    &__content {
      width: unset;
      justify-content: center;
      gap: 60px;
      max-width: none;
    }
  }
}
