.controllersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .plansController {
    display: flex;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    border-radius: 80px;
    background: #f2f2f2;
    max-width: 410px;
    // height: 50px;
    margin: 0 0 24px;

    .controllerButton {
      margin: auto;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 24px;
      border-radius: 80px;
      min-width: 120px;

      color: #f07d00;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &.active {
        background: #fff;
      }
    }
  }

  .taball {
    display: none;
    margin: 0 0 30px;

    &.active {
      display: block;
    }
  }
}

.planos {
  display: none;

  &.active {
    display: block;
  }

  .CardPlan-content{
    display: block !important;
  }
}

.ButtonWhats{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #4fd600;
  color: #000;
  font-weight: 500;
  margin: 8px auto 0;
  width: 250px;
  border-radius: 30px;
  padding: 16px 0px;
}

.globoplaymsg{
  display: none;
  text-align: center;
  color: #000000A6;
  font-size: 14px;

  a{
    text-decoration: underline;
    color: #000b6b;
  }
}

.CardPlan-link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  text-align: center;
  margin: 0 auto;
}

.modal-forms-container{
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  align-items: center;
  flex-direction: center;
  background-color: rgba(0,0,0,.651);
  z-index: 99999;
  overflow: hidden;
  overscroll-behavior: none;

  .modal-forms{
    background-color: white;
    border-radius: 10px;
    max-width: 700px;
    width: 90%;
    margin: 0 auto;

    .modal-head{
      display: flex;
      column-gap: 6px;
      justify-content: space-between;
      padding: 16px 20px;
      border-bottom: 1px solid #e0e0e1;

      span{
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
      }

      .close-button{
        display: flex;
        align-items: center;
        cursor: pointer;

        svg{
          width: 24px;
        }
      }
    }

    .modal-forms-content{
      padding: 16px 20px;

      form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12px 9px;

        p{
          font-size: 15px;
          margin-bottom: 5px;
          line-height: 28px;
        }

        #cidade{
          align-items: center;
          background-color: rgb(244, 244, 244);
          color: rgb(129, 129, 129);
          display: flex;
          font-size: 16px;
          font-weight: 500;
          height: 50px;
          line-height: 24px;
          padding:0px 0px 0px 24px;
          border-radius: 5px;
          margin: 24px 0;
          width: 100%;
        }

        fieldset{
          position: relative;
          margin: 24px 0 0;

          label{
            position: absolute;
            left: 24px;
            color: #818181;
            font-size: 16px;
            font-weight: 500;
            top: 12px;
            transition: all ease-in-out .3s;
            z-index: 1;
          }

          &.active{
            label{
              font-size: 12px !important;
              top: 0;
            }
          }

          &.alert{
            input[type=text]{
              border-color: red;
            }

            label{
              color: red;
            }
          }

          input{
            font-size: 14px;
            padding: 22px 24px 8px;
            height: 48px;
            width: 100%;
          }
        }

        button{
          background-color: #4fd600;
          border-radius: 30px;
          border: none;
          color: #000;
          cursor: pointer;
          font-size: 16px;
          height: 50px;
          line-height: 24px;
          padding: 0 15px;
          width: 100%;
          font-weight: 500;
          margin: 0 auto 16px;
          max-width: 320px;
        }
      }
    }
  }
}
