.TabSumicity {
  min-height: 100px;

  .warning {
    color: red;
  }

  &.default {
    @extend %TabDefault;
  }

  &.bgBlue {
    @extend %TabBlue;
  }

  &.bg-blue-normal {
    @extend %TabBlueNormal;
  }

  &__content {
    display: none;
    margin-top: 30px;

    &.active {
      display: block;
      min-height: 150px;
    }

    @media (min-width: $tablet) {
      margin-top: 40px;
    }
  }

  &__tabs {
    cursor: pointer;
    display: flex;
    gap: 20px;

    &--isInactive {
      display: none;
    }
  }
}

%TabDefault {
  .TabSumicity {
    &__tabs {
      &--title {
        font-size: $size-16;
        padding: 0 45px;

        &.active {
          background: red;
          color: #000b6b;
          font-weight: 700;
          border-bottom: 2px solid #000b6b;
        }
      }
    }
  }
}

%TabBlue {
  .TabSumicity {
    &__tabs {
      flex-direction: column;

      &--title {
        background-color: $greyLight;
        padding: 71px 0;
        padding-left: 40px;
        font-size: 32px;
        color: #6f686869;
        width: 100%;
        border-radius: 7px;
        font-weight: 600;

        &.active {
          background-color: $primaryAnil;
          color: $white;
          text-decoration: underline;
          text-underline-offset: 18px;
          transition: 1s;
        }
      }

      @media (min-width: $tablet) {
        flex-direction: row;
      }
    }
  }
}

%TabBlueNormal {
  .TabSumicity {
    &__tabs {
      overflow: auto;
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 4px;
      margin: 0 -20px;
      padding: 20px;

      &--title {
        background: $white;
        color: $greyMedium;
        padding: 5px 24px;
        border-radius: 17px;
        font-size: $size-16;
        line-height: $line-26;
        font-weight: 700;
        white-space: nowrap;

        &:hover,
        &.active {
          background: $primaryLight;
          color: $white;
        }

        @media (min-width: $tablet) {
          padding: 10px 24px;
          border-radius: 24px;
        }
      }

      @media (min-width: $tablet) {
        margin: 0;
        padding: 0;
      }
    }
  }
}
