.only-text {
  width: 100%;

  .Typography {
    &.block-editor-rich-text__editable {
      padding-bottom: 20px;
    }
  }

  &__content {
    width: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    color: inherit;

    li {
      list-style: disc;
      color: inherit;
      @extend %body;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    color: inherit;
    margin-left: 0;

    li {
      list-style: auto;
      color: inherit;
      @extend %body;
    }
  }
}
