.customTable {
  min-height: 100px;

  &.default {
    @extend %defaultTable;
  }

  &.tableGreyBlue {
    @extend %defaultTable;
    @extend %greyBlueTable;
  }

  &.tableGreyBlueLight {
    @extend %defaultTable;
    @extend %greyBlueLightTable;
  }
}

%defaultTable {
  overflow: auto;

  .customTable {
    &-wrap {
      width: 100%;

      th,
      td {
        padding: 0 10px;
        box-sizing: border-box;
        width: 27.5%;

        &:last-child {
          width: 45%;
          padding-left: 4px;
          padding-right: 4px;

          @media (min-width: $mobile-large) {
            padding-left: 20px;
            padding-right: 20px;
          }

          @media (min-width: $tablet) {
            padding-left: 50px;
            padding-right: 50px;
          }
        }

        @media (min-width: $mobile-large) {
          padding: 0 20px;
          width: 27.5%;

          &:first-child {
            width: 45%;
          }

          &:last-child {
            width: 27.5%;
          }
        }

        @media (min-width: $tablet) {
          padding: 0 50px;
          width: 27.5%;

          &:first-child {
            width: 45%;
          }

          &:last-child {
            width: 27.5%;
          }
        }
      }

      thead {
        tr {
          th {
            color: $primaryDark;
            padding-bottom: 15px;

            &:not(:last-child) {
              border-right: 2px solid $greyMidleLight;

              @media (min-width: $tablet) {
                border: none;
              }
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(2n-1) {
            background: $greyLighten;
          }

          td {
            min-height: 78px;
            padding-top: 15px;
            padding-bottom: 15px;

            &:not(:last-child) {
              border-right: 2px solid $greyMidleLight;
            }
          }
        }
      }

      tfoot {
        tr {
          td {
            padding-top: 15px;
            padding-bottom: 15px;

            &:not(:last-child) {
              border-right: 2px solid $greyMidleLight;

              @media (min-width: $tablet) {
                border: none;
              }
            }
          }
        }
      }
    }

    &-actions {
      width: 100%;
      text-align: center;
    }
  }
}

%greyBlueTable {
  .customTable {
    &-wrap {
      tbody {
        tr {
          &:nth-child(2n) {
            background: $primaryAnil;

            h3,
            p {
              color: $white;
            }
          }

          td:first-child {
            width: 70%;
          }
        }
      }
    }
  }
}

%greyBlueLightTable {
  .customTable {
    &-wrap {
      tbody {
        tr {
          &:nth-child(2n) {
            background: $primaryLight;

            h3,
            p {
              color: $white;
            }
          }

          td:first-child {
            width: 70%;
          }
        }
      }
    }
  }
}
