.SwiperGeral {
  position: relative;

  &__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &.editor {
      overflow-x: auto;
    }
  }

  &__slider {
    padding: 0 20px 10px;
    position: relative;

    .swiper-wrapper {
      align-items: stretch;

      // item para alinhar slider ao container
      .fake-item {
        height: 200px;
        margin-right: 0 !important;
        background: red;
        width: 0 !important;

        &.transparent {
          height: 10px;
          background: transparent;
        }

        @media (min-width: $large) {
          width: calc(5vw - 20px) !important;
        }

        @media (min-width: 1300px) {
          width: calc(((100vw - $container) / 2) - 20px) !important;
        }
      }
    }
  }

  .swiper-pagination {
    bottom: -35px;

    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;

      &.swiper-pagination-bullet-active {
        background: $primaryDarker;
      }
    }

    &.swiper-pagination-bullets-dynamic {
      .swiper-pagination-bullet {
        transform: scale(0.8);
      }
    }
  }

  .swiper-wrapper {
    > .block-editor-inner-blocks {
      width: 100%;

      > .block-editor-block-list__layout {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;

        > .block-editor-block-list__block {
          margin: 0 10px;
          min-width: 300px;
        }
      }

      .wp-block {
        max-width: 350px !important;
        margin: 0;
      }
    }
  }

  .custom-swiper {
    &-prev {
      &:after {
        transform: rotate(90deg);
      }

      @media (min-width: $large) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 100%;
      }
    }

    &-next {
      &:after {
        transform: rotate(-90deg);
      }

      @media (min-width: $large) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
      }
    }

    &-prev,
    &-next {
      width: 55px;
      height: 51px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primaryAnil;
      border-radius: 5px;
      transition: 0.2s;
      cursor: pointer;
      pointer-events: all;
      margin: 30px 5px 0;

      &:hover {
        background: $primaryDark;
      }

      &:after {
        content: '\e901';
        font-size: $size-32;
        font-family: 'icomoon', sans-serif !important;
      }
    }
  }

  @media (min-width: $large) {
    &__slider {
      &.isCentered {
        .swiper-wrapper {
          justify-content: center;
        }
      }
    }

    .navigation-disabled {
      display: none;
    }
  }
}
