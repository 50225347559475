.AboutUs {
  background-color: $primaryAnil;

  .about-us {
    display: flex;
    padding: 50px 0 50px;
    justify-content: space-between;
    transition: all 0.5s;

    &-content {
      transition: all 0.5s;

      &__text--heading {
        width: 63%;
        color: #fff;
        font-size: $size-16;
        line-height: 28.8px;
        margin: 0 0 0 40px;
        transition: all 0.5s;
      }
    }

    &-content__image {
      display: none;
      transition: all 0.5s;
    }
  }

  @media (min-width: $mobile-large) {
    .about-us {
      &-content {
        &__text--heading {
          width: 65%;
          font-size: $size-22;
        }
      }
    }
  }

  @media (min-width: $tablet) {
    .about-us {
      &-content {
        &__text--heading {
          width: 60%;
          line-height: 57.6px;
          font-size: $size-28;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  @media (min-width: $laptop) {
    display: flex;

    .about-us {
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      padding: 0;

      &-content {
        &__text--heading {
          width: 90%;
          font-size: $size-24;
        }
      }
    }

    .about-us-content__image {
      display: inline-block;
      margin-right: 0px;
      width: 50%;
      height: 50%;
    }
  }

  @media (min-width: $container) {
    .about-us {
      &-content {
        &__text {
          transform: translate(60px, 0px);

          &--heading {
            font-size: $size-32;
            width: 550px;
          }
        }
      }
    }

    .about-us-content__image {
      width: auto;
      height: auto;
    }
  }
}

.AboutUs-card {
  overflow: hidden;

  .about-us-cards {
    &-content {
      display: flex;
      flex-direction: column;
      line-height: 36px;
      letter-spacing: 0.75px;
    }

    &-swiper__read-more {
      border: none;
      background-color: transparent;
      font-size: $size-16;
      color: $grey;
    }

    &-swiper__cards {
      max-width: 52%;
      order: 1;

      .swiper-wrapper {
        align-items: center;

        .about-us-cards-swiper__card {
          display: flex;
          flex-direction: column;
          min-height: 160px;
          justify-content: space-evenly;

          &--image {
            border-radius: 15px;
            position: absolute;
            min-height: 155px;
            max-width: 182px;
            min-width: 180px;
          }

          &--heading,
          &--content {
            margin: 0;
            position: relative;
            color: $white;
            text-align: center;
            max-width: 182px;
            min-width: 180px;
          }

          &--heading {
            font-size: $size-48;
            letter-spacing: 1px;
            line-height: 54px;
            font-weight: 700;
          }

          &--content {
            padding: 0 5px 0 5px;
            font-size: $size-15;
            letter-spacing: 0.75px;
            line-height: 22px;
            font-weight: 500;
          }
        }

        > .block-editor-inner-blocks {
          margin: 0;

          > .block-editor-block-list__layout {
            margin: 0;
            padding-top: 32px;
            flex-wrap: wrap;
            position: initial;
            height: auto;
            display: flex;
            gap: 60px 50px;
            justify-content: start;

            > .block-editor-block-list__block {
              width: fit-content;
              min-height: 155px;
              max-width: 182px;
              min-width: 180px;
            }

            .about-us-cards-swiper__card {
              margin: 0 !important;
              width: 100%;

              .block-editor-inner-blocks {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &-text {
      order: 2;
    }

    &-swiper__title {
      font-size: $size-24;
      color: $primaryDark;
      font-weight: 600;
      margin-bottom: 0;
    }

    &-swiper__subtitle {
      margin-top: 0;
      order: 3;
      font-size: $size-14;
      font-weight: 400;
      line-height: 26px;
    }
  }

  @media (min-width: $tablet) {
    .about-us-cards {
      &-swiper__cards {
        max-width: 100%;
        .swiper-wrapper {
          justify-content: space-between;
        }
      }

      &-swiper__title {
        font-size: $size-32;
      }

      &-swiper__subtitle {
        font-size: $size-16;
      }
    }
  }

  @media (min-width: $laptop) {
    .about-us-cards {
      &-content {
        justify-content: space-between;
        flex-direction: row;
      }

      &-text {
        order: 1;
        width: 48%;
      }

      &-swiper__title {
        margin: 0 0 17px;
      }

      &-swiper {
        width: 47%;

        .swiper-wrapper {
          padding-top: 32px;
          flex-wrap: wrap;
          position: initial;
          height: auto;
          display: flex;
          gap: 60px 50px;
          justify-content: start;
          margin-left: 90px;

          .about-us-cards-swiper__card {
            width: auto;
            display: flex;
            max-height: 160px;
            height: 142px;

            &--image {
              height: 100%;
              width: 100%;
              border-radius: 15px;
            }

            &:nth-child(1) {
              margin-left: -70px;
            }
          }
        }
      }
    }
  }
}
