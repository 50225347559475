.block-editor-inner-blocks {
  margin-left: unset !important;
  margin-right: unset !important;
  box-sizing: border-box;
}

.components-panel__body {
  .panel-row-full-col {
    display: block;
    margin-bottom: 24px;

    .components-base-control {
      width: 100%;
    }
  }

  .panel-row-double-col {
    margin: 24px 0;

    .components-base-control {
      margin-bottom: 0 !important;
      width: fit-content !important;

      .components-base-control__field {
        margin-bottom: 0 !important;
        display: flex;
        flex-direction: row-reverse;
        column-gap: 15px;
        justify-content: start;

        .components-toggle-control__label,
        .components-form-toggle {
          margin-right: 0;
        }
      }
    }
  }

  .imageControl {
    width: 100%;
    margin-bottom: 24px;

    .components-button.is-link {
      &.imageControl-remove {
        margin-left: 15px;
      }
    }
  }
}
