/* stylelint-disable */
.DropdownText {
  margin-bottom: 24px;

  a {
    color: inherit;
    text-decoration: none;
  }

  & &-col {
    &:nth-child(2) {
      margin-bottom: 50px;
    }
  }

  &-col {
    .Typography {
      font-size: $size-22;
    }
  }

  &-excerpt {
    display: none;
  }

  .wp-block {
    .DropdownText-accordion-item-content {
      display: block;
    }
  }

  &-accordion {
    &-item {
      border-bottom: 1px solid #c0c0c0;

      & &-content {
        display: none;

        p {
          font-size: 12px;
          font-weight: 300;
        }
      }

      & &-title {
        margin: 0;
        padding: 16px 70px 16px 0;
        font-weight: 500;
        line-height: 150%;
        font-size: $size-14;
        color: #424242;
        position: relative;
      }

      .icon {
        position: absolute;
        top: 50%;
        font-size: $size-28;
        right: 30px;
        transform: translateY(-50%);
        transition: 0.5s;
      }

      &--isActive {
        .DropdownText-accordion-item-content {
          display: block;
        }

        .DropdownText-accordion-item-title {
          padding-bottom: 5px;
        }

        .icon {
          top: 33%;
          transform: rotate(-180deg);
        }
      }
    }
  }

  @media (min-width: $large) {
    margin-bottom: 88px;
    .Container {
      display: flex;
      gap: 140px;
    }

    &-excerpt {
      display: block;
    }

    & &-col {
      width: 100%;
      max-width: 560px;
    }

    &-accordion {
      &-item {
        & &-title {
          cursor: pointer;
          padding: 32px 60px 32px 40px;
        }
        & &-content {
          margin-top: -30px;
          padding: 0 40px 5px 40px;
        }
      }
    }
  }
}
