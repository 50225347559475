.locations {
  &.default {
    @extend %locationsDefault;
  }

  .block-editor-block-list__block {
    margin: 0 auto;
  }
}

%locationsDefault {
  padding: 43px 0 54px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .locations {
    &-bg {
      z-index: -1;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: none;

      @media (min-width: $large) {
        display: block;
      }
    }
  }

  .Container {
    > .heading-block .Typography {
      @media (min-width: $tablet) {
        margin-bottom: 0;
      }
    }

    > .paragraph-block {
      width: 80%;
      max-width: 1100px;
    }
  }

  .locate-selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;

    > div {
      width: 100%;

      .list-cities {
        height: 400px;
      }

      @media (min-width: $tablet) {
        width: 50%;
      }
    }

    .Card-col-item {
      overflow: hidden;

      @media (min-width: $tablet) {
        height: 400px;
      }
    }

    @media (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      gap: 50px;
    }

    @media (min-width: $container) {
      gap: 100px;
    }
  }
}
