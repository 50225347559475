.custom-search {
  width: 100%;

  .search-form {
    width: 100%;

    label {
      display: flex;
      align-items: center;

      input {
        width: 100%;
        height: 55px;
        border-radius: 30px;
        padding: 14px 15px;
        margin: 0;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }

        &:focus {
          box-shadow: 0 0 0 0;
          outline: 0;
        }

        @media (min-width: $laptop) {
          padding: 15px;
          padding-left: 45px;
        }
      }

      svg {
        width: 25px;
        height: 25px;
        margin-left: -45px;

        @media (min-width: $laptop) {
          margin-left: -55px;
        }
      }
    }
  }

  &.default {
    @extend %CustomSearchDefault;
  }

  &.white {
    @extend %CustomSearchWhite;
  }
}

%CustomSearchDefault {
  .search-form {
    label {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      input {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        color: rgba(0, 0, 0, 0.5);
        font-weight: 300;
        font-size: $size-14;
        line-height: $line-22;

        &:focus {
          border: 1px solid #7a7474;
        }

        &::placeholder {
          color: rgba(0, 0, 0, 0.5);
          font-weight: 300;
          font-size: $size-14;
          line-height: $line-22;
        }
      }
    }
  }
}

%CustomSearchWhite {
  .search-form {
    label {
      -webkit-tap-highlight-color: rgba($white, 0);

      input {
        -webkit-tap-highlight-color: rgba($white, 0);
        color: $white;
        background: transparent;
        border: 1px solid rgba($white, 0.77);
        font-weight: 400;
        font-size: $size-16;
        line-height: $line-22;

        &:focus {
          border-color: $white;
        }

        &::placeholder {
          color: rgba($white, 0.5);
        }
      }
    }
  }
}
