.formCupom-vertical {
  @extend %formCupom-vertical;
}

.formCupom-horizontal {
  @extend %formCupom-horizontal;
}

.body-open {
  overflow-y: hidden !important;
}

.modalCupom {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;

  .copy-cupom {
    display: block;

    &-invisible {
      display: none;
    }
  }

  .copySuccess {
    margin-top: 10px;
    visibility: hidden;

    &-active {
      visibility: visible;
    }

    &-invisible {
      display: none;
    }
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 450px;
    background: $primaryLight;
    z-index: 6;
    border-radius: 10px;
    padding: 40px 20px;

    .Typography {
      margin: 1rem 0 0;

      @media (min-width: $laptop) {
        margin: 1rem 0;
      }
    }
  }

  &__close {
    position: absolute;
    left: 20px;
    color: white;
    top: 20px;
    font-size: 30px;
    transform: rotate(45deg);

    &:hover {
      color: $black;
      cursor: pointer;
    }

    @media (min-width: $tablet) {
      left: unset;
      right: 20px;
    }
  }

  &.active {
    display: block;
  }
}

//Variantes form
%formCupom-horizontal {
  margin: auto;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: $primaryLight;
  border-radius: 20px;

  .Button {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;

    &:disabled {
      background: $greySoftMedium;
    }
  }

  .formCupom__field {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 1rem;

    input {
      height: 45px;
      border-radius: 9px;

      &::placeholder {
        color: $greySoftMedium;
      }
    }

    &--validation {
      display: none;
      position: absolute;
      bottom: 0;
      transform: translateY(90%);
      color: red;
      padding: 4px 8px;
    }
  }

  .only-text {
    width: 100%;
    margin-bottom: 1.5rem;

    &__content {
      margin-top: 0;

      .Typography {
        line-height: 1.5;
      }

      p {
        text-align: center;
      }
    }
  }

  @media (min-width: $laptop) {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .block-editor-inner-blocks {
      .only-text {
        margin: 0;
        width: 100%;
      }
    }

    .only-text {
      margin: 0;
      width: 34%;
    }

    .formCupom__field {
      width: 22%;
      margin: 0;
    }

    .Button {
      width: 18%;
      margin: 0;
      padding: 16px 25px;
    }
  }
}

%formCupom-vertical {
  margin: auto;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: $primaryLight;
  border-radius: 20px;

  .formCupom__field {
    display: flex;
    position: relative;
    flex-direction: column;

    input {
      height: 45px;
      border-radius: 9px;

      &::placeholder {
        color: $greySoftMedium;
      }
    }

    &--validation {
      display: none;
      position: absolute;
      bottom: 0;
      transform: translateY(90%);
      color: red;
      padding: 4px 8px;
    }
  }

  button {
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    height: 45px;

    &:disabled {
      background: $greySoftMedium;
    }
  }

  .only-text__content {
    margin-top: 0;

    .Typography {
      line-height: 1.5;
    }
  }
}