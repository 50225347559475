.ColorCardsItem {
  width: auto;
  text-align: center;

  &.text-inside {
    @extend %TextInside;
  }

  //To do: Adicionar variante para numeros
  &.text-outside {
    @extend %TextOutside;
  }
}

%TextInside {
  width: 150px;
  border-radius: 24px;
  overflow: hidden;
  padding: 15px;

  figure {
    margin: 20px;
  }

  .Typography {
    color: $white;
  }

  @media (min-width: $tablet) {
    width: 180px;
  }
}

%TextOutside {
  max-width: 100px;

  .ColorCardsItem {
    &__card {
      display: block;
      width: fit-content;
      height: fit-content;

      &--text,
      &--icon {
        display: flex;
        justify-content: center;
        flex-direction: column;

        img {
          max-width: 70px;
        }
      }

      &--content {
        margin-bottom: $marginMicro;
        border-radius: 24px;
        overflow: hidden;

        .ColorCardsItem__card--icon {
          width: 100px;
          height: 100px;
          margin: 0;

          img {
            width: 42px;
            height: 42px;
            object-fit: contain;
            margin: 0 auto;
          }
        }
      }

      &--content > .ColorCardsItem__card--text {
        width: 180px;
        height: 150px;
      }

      &--description {
        width: 100%;
        font-weight: 600;
        font-size: $size-14;
        color: $primaryAnil;
        margin: 0;
      }

      &--text {
        padding: 5px 10px 5px 10px;

        p {
          margin: unset;
        }
      }

      &--number {
        font-size: $size-48;
        font-weight: bold;
        height: unset;
        padding-bottom: 10px;
      }

      &--underNumber {
        font-size: $size-16;
        line-height: $line-22;
        font-weight: 500;
        padding-bottom: 10px;
      }

      &--number,
      &--underNumber {
        color: $white;
      }
    }

    @media (min-width: $tablet) {
      &__card {
        display: block;
        border-radius: 24px;
        width: fit-content;
        height: fit-content;

        &--content {
          min-width: 150px;

          .ColorCardsItem__card--icon {
            width: 100%;
            height: 135px;

            img {
              width: 100px;
              height: 100px;
            }
          }
        }

        &--description {
          font-size: $size-16;
        }
      }

      &--description {
        font-weight: 600;
        font-size: $size-14;
        color: $primaryAnil;
      }
    }
  }

  @media (min-width: $tablet) {
    max-width: 150px;
  }
}
