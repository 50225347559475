/* stylelint-disable */
.home .Plans {
  overflow: hidden;
}

.PlansHome {
  overflow: hidden;
}

.Plans {
  overflow: hidden;

  .activePlanTv {
    display: block;

    // @media (min-width: $large) {
    //   height: 520px;
    // }
  }

  .tab {
    max-width: 930px;
    margin: 0 auto;
    // display: flex;
  }

  .Container-PME {
    // padding-top: 10px;
  }

  .plansComboTv-tab {
    &.tab {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 20px;
      margin-top: 34px;
      max-width: 360px;
    }

    &-text {
      padding: 7px;
    }

    hr {
      margin-top: -3px;
      position: relative;
      width: 100%;
      z-index: -1;
      height: 2px;
      border-width: 0;
      color: $greyDark;
      background-color: $greySoft;
    }

    .active {
      color: $primaryAnil;
      border-bottom: 3px solid $primaryAnil !important;
    }
  }

  .tablinks,
  .plansComboTv-tab-tablinks {
    font-size: $size-14;
    font-weight: 400;
    margin-right: 15px;
    color: $primaryDark;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &.active {
      font-weight: 700;
      border-bottom: 3px solid $primaryDark;
    }
  }

  .PlansTabs {
    min-height: 505px;
    min-width: 301px;
  }

  .plansComboTv-tab {
    text-align: center;
  }

  #plansComboTv {
    flex-direction: row;
    justify-content: center;
    margin-top: 35px;

    .CardPlan-content {
      min-width: 290px;
    }

    @media (max-width: $large) {
      margin-bottom: 10px;
    }

    width: 100%;
  }

  .tabcontent {
    display: none;
    height: auto;
    // margin-top: 30px;
  }

  &-wrap .swiper-wrapper,
  .swiper-wrapper-combo {
    margin-bottom: 24px;
  }

  .swiper-wrapper {
    gap: 30px;
    width: 100%;
    max-width: 1000px;

    @media (min-width: $large) {
      gap: 39px;
      // justify-content: center;
    }

    @media (min-width: $container) {
      gap: 50px;
    }
  }

  .swiper-slide {
    height: auto;
    margin-right: 0 !important;
    //max-width: 300px;
  }

  .swiperPlans,
  .swiperPlansTv {
    height: 100%;
    min-height: 550px;
    margin: 0 auto;
    width: fit-content;
    max-width: 100%;
    padding: 10px 0 0;
    position: relative;
  }

  &-wrapButtons {
    display: flex;
    gap: 16px;
  }

  &-antes,
  &-depois,
  &-prev,
  &-next {
    position: static;
    width: 55px;
    height: 51px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primaryAnil;
    border-radius: 5px;
    transition: 0.1s;

    &.swiper-button-disabled {
      background: rgba(0, 80, 180, 0.58);

      &:hover {
        background: rgba(0, 80, 180, 0.58);
        box-shadow: none;

        &:after {
          color: $white;
        }
      }
    }

    &:hover {
      background: $white;
      background: radial-gradient(
        50% 50% at 50% 50%,
        $primary 0%,
        $primaryDark 100%
      );
    }

    &:after {
      content: '\e901';
      font-size: $size-32;
      transform: rotate(-90deg);
      font-family: 'icomoon' !important;
    }
  }

  &-prev,
  &-antes {
    &:after {
      transform: rotate(90deg);
    }
  }

  @media (min-width: $container) {
    overflow-x: hidden;

    .PlansTabs,
    .PlansCompany {
      box-sizing: border-box;
      min-width: 301px;
    }

    .tab {
      margin-bottom: 20px;
    }

    .swiperPlansTab {
      position: revert;
    }

    .plansComboTv-tab {
      &.tab {
        width: 35%;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 2px;
        min-width: 460px;
        max-width: none;
      }
    }

    .tablinks,
    .plansComboTv-tab-tablinks {
      font-size: $size-15;

      &.active {
        font-weight: 700;
        border-bottom: 3px solid $primaryDark;
      }
    }

    &-wrap {
      position: relative;
    }

    &-wrap .swiper-wrapper,
    .swiper-wrapper-combo {
      gap: 38px;
      margin-bottom: 0;

      // &-lock,
      // .swiper-wrapper-combo-lock {
      //   transform: none !important;
      // }
    }

    .swiperPlans,
    .swiperPlansTabs,
    .swiperPME {
      padding: 20px 10px;
      overflow: hidden;
      margin: 0 auto;
      width: fit-content;
    }

    .swiper-slide {
      max-width: 301px;
      min-width: 300px;
      display: flex;
      justify-content: center;
    }

    &-antes,
    &-depois,
    &-prev,
    &-next {
      position: absolute;
      top: 50%;
      cursor: pointer;
      transform: translateY(-50%);
    }

    &-antes,
    &-prev {
      left: 0;
    }

    &-depois,
    &-next {
      right: 0;
    }
  }

  &-notFound {
    padding: 40px 0;
    text-align: center;
  }
}

.block-editor-block-list__layout {
  .swiper-wrapper {
    gap: 24px;
  }

  .swiper-slide {
    height: 100%;
  }
}
