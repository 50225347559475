.GridCards {
  &.withoutImage {
    @extend %GridWithoutImage;
  }

  &-sep {
    &-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 50px;

      &:not(.editor) {
        > div:not(.swiper-slide):not(.GridCards-button-wrap) {
          width: 100%;
          max-width: 250px;

          @media (min-width: $tablet) {
            width: calc(50% - 40px);
            max-width: 350px;
          }

          @media (min-width: $large) {
            max-width: none;
          }
        }
      }
    }
  }

  &-image-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &-image {
    margin: 0;
    display: none;
    text-align: center;
    border-radius: 40px;
    overflow: hidden;
    width: fit-content;
  }

  &-button-wrap {
    width: 100%;
  }

  .ButtonPlans {
    margin: 0 auto;
    font-size: $size-14;
    display: block;
    width: fit-content;

    &.hidden {
      display: none !important;
    }
  }

  &.small {
    @extend %SpaceSmall;
  }

  @extend %GridCardsBreakpoint;
}

.block-editor {
  .GridCards {
    max-height: 100%;

    .block-editor-inner-blocks {
      width: 100%;
    }

    .block-editor-block-list__layout {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;

      .block-editor-block-list__block {
        width: calc(50% - 10px);
        margin: 0;

        > div:not(.swiper-slide):not(.GridCards-button-wrap) {
          width: 100%;

          .block-editor-block-list__block {
            width: 100%;
          }
        }
      }
    }

    &-wrap {
      height: unset;
      margin-bottom: 30px;

      &.expanded {
        padding: 0 95px !important;
        max-height: 100%;
        height: unset;
      }
    }
  }
}

%GridCardsBreakpoint {
  @media (min-width: $large) {
    .buttonEdit {
      display: table;
      margin: 0 auto;
    }

    .GridCards {
      &-image {
        display: block;
        min-height: 60px;
      }

      &-wrap {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 0fr 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        height: 100%;
        align-items: center;
        gap: 122px;

        &.left {
          direction: rtl;

          .GridCards-sep-wrap {
            direction: ltr;
          }
        }

        &.expanded {
          grid-template-columns: 1fr 1fr 1fr 0.5fr 1fr 1fr;
          gap: 10px;
          padding: 0 83px;
        }
      }

      &-sep {
        &-wrap,
        .block-editor-block-list__layout {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 10px;
          justify-content: space-between;
        }

        .cardInfoChoose {
          width: 47%;
          margin-bottom: 32px;
          margin-left: 0;
          margin-right: 0;

          &:nth-child(n + 3) {
            margin-bottom: 0;
          }
        }

        .wp-block {
          margin-bottom: 0;

          .cardInfoChoose {
            width: 100%;
          }
        }

        &:first-of-type {
          grid-row-start: 1;
          grid-column-start: 1;

          grid-row-end: 4;
          grid-column-end: 4;
        }

        &:nth-child(2) {
          grid-row-start: 1;
          grid-column-start: 4;

          grid-row-end: 4;
          grid-column-end: 6;

          &.expanded {
            grid-column-end: 7;
          }
        }
      }
    }
  }
}

%SpaceSmall {
  .GridCards-wrap {
    grid-template-columns: 0fr 1fr 1fr 1fr 0.3fr;

    .Card-col-item {
      max-width: 320px;
    }
  }
}

%GridWithoutImage {
  .GridCards {
    &-wrap {
      grid-template-columns: 1fr;
    }

    &-sep-wrap {
      > div {
        max-width: unset;
      }
    }
  }
}
