.CardPlan {
  background: $white;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 16px 22px 32px 22px;
  height: 100%;
  font-size: $size-14;
  flex-shrink: unset;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-wrapper {
    padding-top: 15px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  &-label {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    background: $secondaryLigh;
    border-radius: 0px 5px;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.75px;
    padding: 2px 7px;
    color: $white;
  }

  &-content {
    display: none;
    margin-bottom: 5px;
  }

  &-plus {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;
    // font-size: $size-20;
    font-weight: 700;
    line-height: 26px;
    font-size: 30px;
    color: #000b6b;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  &-title {
    font-size: $size-48;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 54px;
    color: $primaryDark;
    position: relative;
    text-align: center;
    width: 100%;

    &-lower::after {
      content: 'download 920 Mbps';
      font-size: $size-14;
      position: absolute;
      font-weight: 400;
      top: 38px;
      left: 0;
    }
  }

  &-price,
  &-combo {
    padding-bottom: 2px;
    margin-bottom: 5px;
    margin-top: 20px;
    color: $primaryDark;
    letter-spacing: 0.5px;
  }

  &-combo {
    font-size: $size-16;
  }

  &-price {
    border-bottom: 1px solid #cecece;
  }

  &-align {
    border-bottom: 1px solid #cecece;
    min-height: 164px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .CardPlan-price {
      border: unset;
    }
  }

  &-oldPrice,
  &-offerInfo {
    display: block;
    color: $primaryDark;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 6px;
    letter-spacing: 0.75px;
  }

  &-oldPrice {
    > span {
      text-decoration: line-through;
    }
  }

  &-offerPrice {
    color: $primaryDark;
    font-weight: 400;
    line-height: 36px;
    display: block;

    span {
      font-size: $size-32;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  &-offerInfo {
    margin: 0;
    font-size: 12px;
  }

  &-planCombo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    img{
      height: 40px;
    }
  }

  .CardPlanTv-offerPrice {
    margin: 10px 0;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }

  & &-list {
    margin-bottom: 16px;

    &-services {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      margin-left: 5px;
    }

    li {
      margin-bottom: 8px;
    }

    /* stylelint-disable */
    > div > span,
    > span {
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: $size-12;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 15px;
      letter-spacing: 0.7px;

      @media (max-width: 437px) {
        letter-spacing: 0.1px;
      }

      a {
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }

      img {
        width: 25px;
        height: 25px;
        border-radius: 4px;
      }

      img:first-child {
        margin-left: 1px;
      }
    }
    /* stylelint-enable */
  }

  & &-streaming {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div {
      display: flex;
      & > span {
        margin: 0;
      }
    }
  }

  & &-link {
    display: table;
    margin: 0 auto;
  }

  @media (min-width: 420px) {
    max-width: 300px;
  }

  @media (min-width: $large) {
    /* Modal Content */
    .modal {
      .titleModal {
        padding: 15px 20px 0px 20px;
      }

      &-content-titlePlans {
        .modal-content-package-title {
          min-width: 400px;
        }
      }

      &-channel-package {
        margin: 20px;
      }

      &-content {
        width: 60%;

        &-channel {
          max-width: 144px;
          max-height: 100px;
          width: 144px;
          height: 92px;

          &-title {
            text-align: center;
          }
        }
      }
    }
  }

  @media (min-width: $container) {
    & &-list {
      /* Modal Content */
      .modal {
        &-content {
          width: 50%;
        }
      }
    }
  }

  @media (min-width: ($container + 200px)) {
    & &-list {
      /* Modal Content */
      .modal {
        &-content {
          width: 45%;
        }
      }
    }
  }
}

.CardPlanTv {
  width: auto;
  min-width: 300px;

  @media (min-width: 420px) {
    max-width: 300px;
  }
  @media (min-width: $large) {
    min-height: 505px;
  }
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4);

  /* Black w/ opacity */
  &-content-button-package {
    display: flex;
    justify-content: space-between;
  }

  &-channel-package {
    border: 1px solid $greyLight;
    border-radius: 5px;
    margin: 12px;
    max-height: 523px;
    overflow: scroll;
    scrollbar-width: none;

    .active-channel {
      .icon {
        transition: 0.5s;
        transform: rotate(-180deg);
      }
    }
  }

  &-channel-package::-webkit-scrollbar {
    display: none;
  }

  /* The Close Button */
  .close {
    color: $grey;
    float: right;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    top: -5px;
  }

  .icon-keyboard_arrow_down {
    color: $grey;
    font-size: 24px;
    transition: 0.5s;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .is-has-hd {
    width: 100%;
    height: 60px;
    text-align: center;

    &-text {
      background-color: $primaryLight;
      color: $white;
      font-weight: 500;
      position: relative;
      margin: 0;
    }
  }

  /* Modal Content */
  &-content-titlePlans {
    font-size: $size-16;
    line-height: 24px;
    font-weight: 600;
    color: $primaryAnil;

    .modal-content-package-title {
      position: relative;
      z-index: 999;
      background: white;
      word-break: break-all;
      max-width: 165px;
    }
  }

  .titleModal {
    background-color: #fefefe;
    margin: auto;
    padding: 5px 10px 20px 20px;
    border: 1px solid $greyLight;
    border-radius: 10px 10px 0 0;
    border-top: none;
    border-left: none;
    border-right: none;
    overflow-y: hidden;
    position: sticky;
    top: 0;
    z-index: 999;
    height: 55px;
  }

  &-content {
    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888;
    border-radius: 10px;
    width: 100%;
    max-height: 600px;
    max-width: 550px;
    overflow-y: scroll;
    scrollbar-width: none;

    &-title {
      margin: 7px 0px;
      font-size: $size-14;
      line-height: 21px;
      font-weight: 400;
      border-top: 1px solid $greyLight;
      padding: 10px 16px;
      display: flex;
      justify-content: space-between;
      pointer-events: all;
    }

    &-title:nth-child(1) {
      border: none;
    }

    &-button {
      display: flex;
      justify-content: space-between;
      margin: 7px 0px;
      flex-wrap: wrap;

      &-title {
        text-transform: uppercase;
        font-weight: 700;
        padding: 15px 40px;
        margin: 6px 0px;
        color: $white;
        background-color: $greyDark;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &.active {
          background-color: $primaryLight;
        }
      }
    }

    &-channel {
      display: flex;
      flex-direction: column;
      max-width: 144px;
      max-height: 100px;
      width: 120px;
      height: 90px;
      margin-bottom: 10px;
      box-shadow: 1px 2px 8px #aaaaaa;

      &-image {
        padding-top: 5px;
        min-height: 45%;
        max-height: 45%;
        width: 50px;
        margin: 0 auto;
        object-fit: contain;
      }

      &-title {
        height: 100%;
        display: flex;
        justify-content: center;
        padding-top: 5px;
        font-size: $size-12;
      }
    }
  }

  &-content::-webkit-scrollbar {
    display: none;
  }

  &-channel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0px 20px;
    padding: 0px 16px;
  }
}
