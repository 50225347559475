.SelfServiceCard {
  &__item {
    &.defaultSelf {
      @extend %CardDefaultSelf;
    }

    &.horizontalBlueSelf {
      @extend %CardHorizontalBlueSelf;
    }
  }
}

%CardDefaultSelf {
  display: flex;
  height: 48px;
  background-color: $greyLighten;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;
  border: none;

  .SelfServiceCard__item {
    &--icon {
      background-color: $primaryAnil;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      min-width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;

      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(132deg)
          brightness(103%) contrast(103%);
        padding: 8px;
      }
    }

    &--text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-left: 16px;
      text-align: left;
    }

    &--arrow {
      width: 38px;

      svg {
        position: absolute;
        right: 15px;
        top: 16px;
      }
    }
  }

  @media (min-width: $tablet) {
    width: 190px;
    height: 170px;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    align-items: unset;

    .SelfServiceCard__item {
      &--icon {
        background-color: transparent;
        height: unset;
        width: unset;
        display: unset;
        margin: 24px 16px auto 16px;
        max-width: 45px;

        img {
          padding: unset;
          filter: unset;
        }
      }

      &--text {
        margin: auto 16px 24px 16px;
        font-weight: 500;
      }

      &--arrow {
        display: none;
      }
    }
  }
}

.wp-block .SelfServiceCard__item--arrow {
  display: none;
}

%CardHorizontalBlueSelf {
  background-color: $primaryLight;
  border-bottom: 4px solid $secondaryLighten;
  border-radius: 12px;
  width: 230px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 105px;
  transition: 0.3s;

  &:hover {
    background-color: $primaryAnil;
  }

  .SelfServiceCard__item {
    &--icon {
      width: 30%;
      text-align: center;
      margin: 0;
    }
    &--text {
      color: $white;
      font-weight: 500;
      font-size: $size-16;
      line-height: $size-28;
    }
  }

  @media (min-width: $tablet) {
    width: 300px;
    margin: 0 10px;
  }
}
