.Card {
  overflow: hidden;
  max-width: unset;

  //Estilizações padrões para o card
  &-col {
    &-item {
      display: flex;
      align-items: center;
      align-content: center;
      flex-direction: column;
      margin: 0 auto;
      min-height: unset;

      &.hAlign {
        &-left {
          align-items: flex-start;
        }

        &-center {
          align-items: center;
        }

        &-right {
          align-items: flex-end;
        }
      }

      &:not(.swiper-slide) {
        width: 100%;
      }

      &.removeImage {
        figure {
          display: none !important;
        }
      }

      //Variações do card
      &.default {
        @extend %CardDefault;
      }

      &.defaultHorizontal {
        @extend %CardDefaultHorizontal;
      }

      &.defaultHorizontalBig {
        @extend %CardDefaultHorizontalBig;
      }

      &.bordered {
        @extend %CardBordered;
      }

      &.borderedHorizontal {
        @extend %CardBorderedHorizontal;
      }

      &.bgColored {
        @extend %CardBgColored;
      }

      &.cover {
        @extend %CardCover;
      }

      &.coverWithText {
        @extend %CardCoverWithText;
      }

      &.titleColored {
        @extend %CardColored;
      }

      &.locate {
        @extend %CardLocate;
      }

      &.search {
        @extend %CardSearch;
      }

      &.stepPartner {
        @extend %CardStepPartner;
      }

      &.withTopics {
        @extend %CardWithTopics;
      }

      &.withTopicsExtended {
        @extend %CardWithTopics-extended;
      }

      &.withBackground {
        @extend %CardWithBackground;
      }

      &.circular {
        @extend %CardCircular;
      }

      &.rokuCard {
        @extend %CardRoku;
      }

      &.wp-editor {
        @extend %card-wp-panel;
      }

      &.withoutNumber {
        @extend %card-without-number;
      }
    }
  }
}

.block-editor-inner-blocks {
  .block-editor-block-list__layout {
    .wp-block {
      .Card-col-item {
        width: 235px;
        height: 235px;

        .Typography {
          padding: 0;
        }
      }
    }
  }
}

%CardDefault {
  min-width: 100px;

  .Card-col-item {
    &-image {
      height: 75px;
      width: auto;

      img {
        display: block;
        height: 100%;
        width: auto;
      }
    }

    @media (min-width: $tablet) {
      min-height: 250px;
    }
  }

  .imgNormal {
    height: 75px;
  }

  .imgBig {
    height: 125px;
  }
}

%CardDefaultHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;

  .Card-col-item {
    &-image {
      margin: 0;

      img {
        width: 100px;
        height: auto;
      }
    }

    &-text {
      padding-left: 15px;
      min-height: 100px;
      width: 75%;

      a {
        font-weight: 600;
        text-decoration: none;
      }

      .only-text .Typography.block-editor-rich-text__editable {
        padding-bottom: unset;
      }
    }
  }

  &.textAfter {
    flex-direction: column;

    .Card-col-item {
      &-text {
        margin-top: 15px;
        width: 100%;
        padding-left: 0;
      }
    }

    @media (min-width: $tablet) {
      flex-direction: row;

      .Card-col-item {
        &-text {
          margin-top: 0;
          width: 75%;
          padding-left: 15px;
        }
      }
    }
  }

  &.wp-editor {
    margin-left: 0;
  }
}

%CardDefaultHorizontalBig {
  display: flex;
  flex-direction: column;
  align-items: center;

  .Card-col-item {
    overflow: hidden;

    &-image {
      border-radius: 27px;
      overflow: hidden;
      width: 100%;
      max-width: 370px;
      height: auto;
      margin: 0;

      img {
        transition: 1s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &-text {
      max-width: 370px;
      padding-top: 15px;

      .Typography.right {
        text-align: left;
      }
    }
  }

  @media (min-width: $tablet) {
    flex-direction: row;

    .Card-col-item {
      &-text {
        margin-left: 25px;
        max-width: 470px;

        .Typography.right {
          text-align: right;
        }
      }
    }

    &.hAlign-center {
      justify-content: center;
    }

    &.hAlign-right {
      flex-direction: row-reverse;

      .Card-col-item {
        &-text {
          margin-right: 25px;
        }
      }
    }
  }
}

%CardBordered {
  background: $white;
  width: 100%;
  height: auto;
  text-align: center;
  padding-bottom: 30px;
  box-shadow: -1px -1px 4px $black-opacity, 1px 1px 4px $black-opacity;
  border-radius: 10px;

  .Card-col-item {
    &-image {
      height: 200px;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;

      img {
        height: 200px;
        width: 100%;
        object-fit: cover;
      }

      &.originalSize {
        height: unset;
        width: unset;
        padding-top: 1.5rem;

        img {
          height: auto;
          width: auto;
        }
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

%CardBorderedHorizontal {
  background: $white;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  max-width: 400px;
  min-height: 250px;
  box-shadow: 0px 1px 9px rgba($black, 0.25);
  border-radius: 10px;
  align-items: flex-start;
  justify-content: center;

  .Card-col-item {
    &-image {
      z-index: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;

      @media (min-width: $tablet) {
        width: 196px;
        top: 50%;
        transform: translateY(-50%);
      }

      @media (min-width: $container) {
        width: 288px;
        right: 20px;
      }
    }

    &-text {
      position: relative;
      z-index: 2;
      padding: 30px 15px;

      @media (min-width: $container) {
        padding: 15px;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .Button.withIcon:not(.auto-width) {
    margin-top: 6px;
    width: 230px;
  }

  @media (min-width: $container) {
    max-width: 570px;
    min-height: 280px;
  }
}

%CardBgColored {
  height: 190px;
  border-radius: 27px;
  display: flex;
  justify-content: center;
}

%CardCover {
  position: relative;

  > figure {
    border-radius: 15px;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
    }
  }

  > .Card-col-item-text {
    width: 100%;
    max-width: 90%;

    > .only-text {
      position: absolute;
      top: 20px;
      left: 25px;
      width: calc(100% - 50px);
    }

    > .Card-col-item {
      margin-top: -60px;

      .Card-col-item-image {
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 3px 5px 10px rgba($black, 0.25);
        height: 82px;
        margin-bottom: 10px;

        img {
          height: 100%;
          width: auto;
        }
      }

      @media (min-width: $tablet) {
        margin-top: -90px;
      }

      @media (min-width: $large) {
        margin-top: -130px;
      }
    }

    // Estilos para o painel
    > .block-editor-inner-blocks {
      > .block-editor-block-list__layout {
        position: unset;

        > .wp-block {
          position: unset;

          > .only-text {
            position: absolute;
            top: 20px;
            left: 25px;
            width: calc(100% - 50px);

            .Typography {
              padding: 0;
            }
          }

          &[data-type='app/card-sumicity'] {
            margin-top: -150px;

            .Card-col-item {
              &-image {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

%CardCoverWithText {
  border-radius: 27px;
  overflow: hidden;
  max-width: 370px;
  margin: auto;

  > .Card-col-item-image {
    min-height: 300px;
    width: 100%;
    height: 100%;
    position: relative;
    transition: 2s;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      transition: transform 0.4s ease-in-out;
      background-position: center;
    }

    &::before {
      content: '';
      position: absolute;
      top: 40%;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        0deg,
        #000000 0%,
        rgba(0, 0, 0, 0) 89.84%,
        rgba(217, 217, 217, 0) 203.22%
      );
      z-index: 1;
    }

    &:hover {
      &::after {
        transform: scale(1.2);
      }
    }
  }

  .Card-col-item-title {
    color: $white;
    margin-bottom: 25px;
    font-size: $size-20;
    z-index: 1;
  }
}

%CardColored {
  border-radius: 27px;
  overflow: hidden;
  height: 100%;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;

  .Card-col-item {
    &-image {
      width: 100%;
    }

    &-title {
      text-align: center;
      color: $white;
    }

    &-text {
      padding: 20px;
    }
  }

  &.cardBlue {
    border: solid 1px $primaryLight;

    .Card-col-item-image {
      background-color: $primaryLight;
    }
  }

  &.cardBlueNavy {
    border: solid 1px $primaryNavy;

    .Card-col-item-image {
      background-color: $primaryNavy;
    }
  }

  @media (min-width: $tablet) {
    margin: 0 auto;
  }
}

%CardLocate {
  border: 1px solid $white;
  border-radius: 15px;
  padding: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: $white;

  svg {
    margin-bottom: 30px;
  }

  .city {
    font-size: $size-22;
    line-height: $line-24;
    font-weight: 500;
    margin: 0 0 8px;
    text-transform: uppercase;
  }

  .address {
    font-size: $size-16;
    line-height: $line-20;
    margin: 0 0 8px;
  }

  .tel {
    font-size: $size-16;
    line-height: $line-28;
    font-weight: 700;
    margin: 0 0 8px;

    &:hover,
    &:focus,
    &:active {
      color: $greySoft;
      outline: none;
    }
  }

  .schedules {
    font-size: $size-12;
    line-height: $line-20;
    margin: 0 0 8px;
  }

  .gmaps {
    font-size: $size-14;
    line-height: $line-24;
    font-weight: 700;
    margin: 0;

    &:hover,
    &:focus,
    &:active {
      color: $greySoft;
      outline: none;
    }
  }

  @media (min-width: $container) {
    padding: 50px;

    .city {
      font-size: $size-20;
      line-height: $line-36;
      font-weight: 700;
    }

    .address {
      font-size: $size-20;
      line-height: $line-36;
      font-weight: 700;
      margin-bottom: 0;
    }

    .tel {
      font-size: $size-16;
      line-height: $line-28;
      margin-bottom: 0;
    }
  }
}

%CardSearch {
  background: $white;
  width: 100%;
  align-items: flex-start;
  min-height: unset;
  border-bottom: 2px solid $greyLighten;

  .Card-col-item {
    &-image {
      display: none;
    }

    &-text {
      padding: unset;
    }
  }
}

%CardStepPartner {
  border: solid 0.5px $white;
  border-radius: 12px;
  height: 180px;
  width: 225px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

// Styles for Step Partner card with columns block works right

@media (max-width: $extraLarge) {
  /* stylelint-disable */

  .wp-block-columns:not(.is-not-stacked-on-mobile)
    > .wp-block-column:has(.stepPartner) {
    flex-basis: unset;
  }

  /* stylelint-enable */

  .wp-block-columns:has(.stepPartner) {
    flex-wrap: wrap !important;
  }
}

// ------------------------------------------------------

%CardWithTopics {
  background: $white;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 0px rgba($black, 0.05);
  padding-bottom: 17px;
  min-width: 180px;
  max-width: 220px;
  margin: 0 auto;

  .Card-col-item {
    &-text {
      width: 100%;

      > .heading-block {
        margin-bottom: 18px;
        width: 100%;
        border-bottom: 1px solid $greyLighten;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px 0;
      }

      .TopicItem {
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
        margin-bottom: 21px;

        .TopicItem__title--text {
          margin: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

%CardWithTopics-extended {
  box-shadow: 0px 1px 9px rgba($black, 0.25);
  border-radius: 7px;
  background-color: rgba(217, 217, 217, 0.15);
  padding-bottom: 20px;

  .Card-col-item {
    &-image {
      height: 75px;
      width: 100%;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-title {
      color: $white;
      font-size: $size-24;
      line-height: 30px;
      text-align: center;
    }

    &-text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .only-text:first-child {
        background-color: $white;
        min-height: 130px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .only-text__content,
      .TopicItem {
        padding: 0 20px;
      }

      .TopicItem {
        margin: 0;

        &__title {
          margin: 0;
          padding: 5px 0;
          font-size: $size-11;
        }

        .only-text {
          min-height: unset;
        }

        .only-text__content {
          padding: 0;

          p {
            line-height: $size-14;
          }
        }
      }

      .Button {
        margin-top: 20px;
      }
    }
  }
}

%CardWithBackground {
  filter: drop-shadow(-2px -2px 4px rgba($black, 0.05))
    drop-shadow(2px 2px 4px rgba($black, 0.05));
  background: $white;
  padding: 25px 0px;
  border-radius: 10px;
  height: 100%;
  display: flex;
  justify-content: start;

  .Card-col-item-image {
    height: 80px;
    display: flex;
    align-items: center;
  }

  @extend %CardDefault;
}

%CardCircular {
  box-sizing: border-box;
  width: 235px;
  height: 235px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 4px solid $primaryLight;
  position: relative;
  margin: 0 auto;

  .Card-col-item {
    &-circle {
      width: 77px;
      height: 77px;
      border-radius: 50%;
      background-color: $primaryLight;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-number {
      color: $white;
      font-size: $size-56;
      font-weight: 700;
    }

    &-text {
      margin-top: 85px;
    }
  }
}

%card-without-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 260px;
  padding: 0 20px;
  
  .Card-col-item {
    &-text {
      margin-top: 0;
    }
  }
}

%CardRoku {
  height: 100%;
  background: $white;
  padding: 20px 10px;
  border-radius: 10px;
  filter: drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05))
    drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.05));
  max-width: 350px;
  margin: 0 auto;

  .Card-col-item-image {
    height: 75px;
    display: flex;
    align-items: center;
  }
}

// Styles for circular card with columns block works right

@media (max-width: $extraLarge) {
  /* stylelint-disable */

  .wp-block-columns:not(.is-not-stacked-on-mobile)
    > .wp-block-column:has(.circular) {
    flex-basis: unset;
  }

  /* stylelint-enable */

  .wp-block-columns:has(.circular) {
    flex-wrap: wrap !important;
  }
}

// ------------------------------------------------------

%card-wp-panel {
  &.borderedHorizontal {
    .Button.withIcon {
      width: 200px;
    }
  }

  &.withTopics {
    .Card-col-item-text {
      > .block-editor-inner-blocks {
        > .block-editor-block-list__layout > .wp-block {
          margin-bottom: 21px;

          &:first-child {
            margin-bottom: 18px;

            .heading-block {
              width: 100%;
              border-bottom: 1px solid $greyLighten;
              min-height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              padding: 10px 0;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .block-editor-block-list__block {
    margin: 0;
  }

  .is-selected {
    background: rgba($greyLighten, 0.8);
  }
}
