.TopicItem {
  @extend %TopicItemDefault;
}

// Atualmente estes estilos funcionam tanto para
// a variante default quanto para a variante just-title
%TopicItemDefault {
  .TopicItem {
    &__title {
      position: relative;
      display: flex;
      justify-content: flex-start;

      .topic-with-image {
        text-align: center;

        img {
          width: 40%;
        }

        .paragraph-block {
          .only-text__content {
            max-width: 400px;

            p {
              font-size: .85rem;
              line-height: 1rem;
            }
          }
        }

        @media (min-width: $tablet) {
          .paragraph-block {
            max-width: 536px;
          }
        }

        @media (min-width: $laptop) {
          img {
            width: 60%;
          }
        }
      }

      &.top {
        align-items: flex-start;

        .TopicItem__title--icon {
          transform: translateY(5px);
        }
      }

      &.middle {
        align-items: center;
      }

      &.bottom {
        align-items: flex-end;
      }

      &--icon {
        height: 24px;
        width: 24px;
        margin-right: 15px;
      }
    }
  }
}
