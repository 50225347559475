.TextRow {
  width: 100%;
  margin-left: unset;
  margin-right: unset;

  &-title,
  &-description {
    text-align: center;

    @media (min-width: $laptop) {
      text-align: left;
    }
  }

  &-title {
    color: $primaryAnil;
    font-size: $size-16;
    font-weight: 400;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  &-description {
    color: $grey;
    font-size: $size-14;
    font-weight: 400;
    line-height: 28px;
    margin: 0;

    @media (min-width: $laptop) {
      font-size: $size-16;
    }
  }

  &:first-child {
    .TextRow-title {
      margin-top: 0;
    }
  }
}

.block-editor {
  .TextRow {
    padding-right: 40px;
  }
}
