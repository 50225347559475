.GridImageText {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  p {
    margin: unset;
    line-height: 115%;
  }

  &__item {
    color: $white;
    border-radius: 9px;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--text {
      font-weight: 500;
    }
  }

  &__image {
    border-radius: 9px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__wrapper {
    &:first-child {
      width: 60%;
    }

    &:nth-child(2) {
      width: 35%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .GridImageText__item {
        background-color: $secondaryLigh;
        height: 55%;

        &--number {
          font-size: $size-26;
          font-weight: 700;
        }

        &--text {
          font-size: $size-12;
        }

        @media (min-width: $container) {
          &--number {
            font-size: 82px;
          }

          &--text {
            font-size: 34px;
          }
        }
      }
    }

    &:last-child {
      display: flex;
      width: 90%;
      margin: 0 auto;
      gap: 10px;
      justify-content: center;

      .GridImageText__item {
        &:first-child {
          background-color: $tertiaryMedium;
          width: 50%;

          .GridImageText__item {
            &--number {
              font-size: $size-54;
              font-weight: 700;
            }

            &--text {
              font-size: $size-12;
            }

            @media (min-width: $container) {
              &--number {
                font-size: 96px;
              }

              &--text {
                font-size: $size-20;
              }
            }
          }
        }

        /* stylelint-disable */
        &:last-child {
          background-color: $primaryAnil;
          width: 40%;

          .GridImageText__item {
            &--number {
              font-size: $size-28;
              font-weight: 700;
            }

            &--text {
              font-size: $size-8;
              line-height: 180%;
            }

            @media (min-width: $container) {
              &--number {
                font-size: $size-48;
              }

              &--text {
                font-size: $size-14;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $tablet) {
    gap: 3%;
    justify-content: space-between;

    &__item,
    &__image {
      border-radius: 27px;
    }

    &__wrapper {
      &:first-child {
        width: 45%;
      }

      &:nth-child(2) {
        gap: 30px;
        width: 28%;
      }

      &:last-child {
        flex-direction: column-reverse;
        width: 20%;
        justify-content: start;
        margin: unset;
        margin-top: 40px;
        gap: 30px;

        .GridImageText__item {
          &:first-child {
            width: 100%;
          }

          &:last-child {
            width: 80%;
          }
        }
      }
    }
  }
}
