.GridButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;

  .Button {
    margin: 0;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.left-center {
    justify-content: flex-start;

    @media (min-width: $tablet) {
      justify-content: center;
    }
  }

  &.center-left {
    justify-content: center;

    @media (min-width: $tablet) {
      justify-content: flex-start;
    }
  }

  .wp-block {
    width: unset;
  }

  .block-editor-block-list__layout {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }

  @media (min-width: $tablet) {
    flex-direction: row;
  }
}
