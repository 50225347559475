.CheckList {
  gap: 8px;
  font-weight: 400;
  font-size: $size-12;
  line-height: $line-20;
  letter-spacing: 0.35px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  color: $grey;

  &-icon {
    content: '';
    padding-top: 2px;
    font-family: 'icomoon', sans-serif !important;
    color: $white;
    line-height: 120%;
    border-radius: 100px;
    display: flex;
    align-items: center;
    width: 22px;
    min-width: 22px;
    height: 22px;
    justify-content: center;
    background: $primary;
  }
}
