.Tab {
  min-height: 50px;

  .tablinks,
  .plansComboTv-tab-tablinks {
    font-size: $size-14;
    font-weight: 400;
    margin-right: 15px;
    color: $primaryDark;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &.pagetab {
      display: none;
    }

    &.active {
      font-weight: 700;
      border-bottom: 3px solid $primaryDark;
    }

    @media (min-width: $mobile) {
      font-size: $size-15;
    }
  }

  &-content {
    display: none;

    &.active {
      display: block;
    }
  }

  .Plans {
    .tab {
      display: none;
    }
  }

  .block-editor-block-list__block {
    .Tab-content {
      padding-bottom: 50px;
    }
  }
}

.block-editor {
  .tablinks.pagetab {
    display: inline-block;
  }
}
