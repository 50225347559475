@import '../mixins/ScrollBar';

.list-cities {
  &.default {
    @extend %listCitiesDefault;
  }

  .hide {
    display: none;
  }
}

%listCitiesDefault {
  position: relative;
  z-index: 1;
  overflow: auto;
  border: 1px solid $white;
  border-radius: 15px;
  padding: 9px 24px;
  box-sizing: border-box;

  .list-cities {
    &-item {
      width: 100%;

      strong {
        display: block;
        width: 100%;
      }

      button {
        width: 100%;
        text-decoration: none;
        line-height: $line-24;

        &:hover,
        &.active {
          font-weight: 700;
        }
      }
    }
  }

  @include ScrollBar(12px, $greySecondary, $white, 0 15px 15px 0);

  @media (min-width: $large) {
    padding: 20px 35px;
  }
}
