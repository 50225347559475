.SectionMap {
  .Container {
    position: relative;

    .Typography {
      width: 100%;

      @media (min-width: $large) {
        width: 40%;
      }

      @media (min-width: $laptop) {
        width: calc(100% - 700px);
      }
    }

    .Button {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primaryAnil;
      border-radius: 50px;
      max-width: 275px;
      padding: 16px;
      cursor: pointer;
    }

    .Button p {
      font-size: $size-16;
      font-weight: 500;
      margin: 0;
      margin-left: 8px;
    }
  }

  &-description {
    font-size: $size-16;
    line-height: 32.4px;
  }

  &-image {
    width: 100%;
  }

  &-icon {
    width: 28px;
    height: 28px;
  }

  @media (min-width: $large) {
    .Container {
      display: flex;
      flex-direction: column;

      .Typography {
        max-width: 480px;
      }

      .Button {
        justify-content: flex-start;
        margin: 0;
      }
    }

    &-image {
      width: 90%;
    }

    &-description {
      width: 480px;
    }

    &-sub {
      width: 480px;
    }

    &-sep {
      order: 1;
      position: absolute;
      right: -160px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media (min-width: $laptop) {
    &-image {
      width: 100%;
    }

    &-sep {
      right: -30px;
    }
  }
}
