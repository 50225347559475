.StreamingContent {
  width: 100%;
  padding: 0 17px;
  overflow: hidden;
  padding-bottom: 18px;

  &-Swiper {
    z-index: 1;
    position: relative;

    figure {
      display: flex;
      justify-content: center;
    }

    .swiper-slide-prev {
      opacity: 0 !important;
    }

    .swiper-button-next {
      right: 20%;
    }

    .swiper-button-prev {
      left: 20%;
    }

    .swiper-wrapper {
      position: relative;
      z-index: 1;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 50px;
      height: 50px;
      font-weight: 600;
      border: 1px solid $primaryAnil;
      color: $primaryAnil;
      border-radius: 5px;
      display: none;
      margin-top: -60px;
      position: absolute;
      z-index: 999;
      pointer-events: all;
      cursor: pointer;

      @media screen and (min-width: 960px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .swiper-pagination {
      width: 100%;
      bottom: -18px;

      &-bullet {
        margin: 0 4px;
        border: 1px solid black;
        background: white;

        &-active {
          background: black;
        }
      }
    }

    .swiper-slide {
      z-index: 2 !important;
    }
  }

  &-Container {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  &-CardTitle {
    font-size: 17px;
    line-height: 27px;
    color: $primaryAnil;
    margin: 18px auto;
  }

  &-CardDescription {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #424242;
    margin: 0 auto;
    display: block;
  }

  &-CardDescription,
  &-CardTitle {
    font-family: $poppins;
    font-weight: 400;
    text-align: center;
    max-width: 500px;
  }

  .block-editor-block-list__layout {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    .block-editor-block-list__block {
      width: fit-content;
      margin: 0 !important;

      &:not(:last-child) {
        margin: 0 20px 0 0 !important;
      }
    }
  }

  &-edit {
    &-ContainerCard {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      max-width: 400px;
      text-align: center;
    }

    &-Image {
      margin: 0 auto;
      max-width: 400px;
    }
  }
}
