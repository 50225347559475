.grid-sumicity {
  min-height: 100px;

  &-content {
    @extend %GridContent;
  }
}

%GridContent {
  display: flex;
  gap: 16px;
  width: 100vw;
  padding: 0 20px 30px;
  margin-left: -20px;

  &:not(.scrollable) {
    flex-wrap: wrap;

    &:not(.wp-editor) {
      @extend %GridAligns;

      @for $size from 1 through 12 {
        &.size-#{$size} {
          > div,
          > section {
            @media (min-width: $tablet) {
              width: calc((100% / $size - 20px) * 2);
            }

            @media (min-width: $large) {
              width: calc(100% / $size - 50px);
              margin: 0;
            }
          }
        }
      }
    }
  }

  &.scrollable {
    overflow-x: auto;

    &:not(.wp-editor) {
      @extend %GridAligns;

      @for $size from 1 through 12 {
        &.size-#{$size} {
          > div,
          > section {
            width: calc(100% / $size);
            margin: 0;
          }
        }
      }
    }
  }

  &.wp-editor {
    @extend %WP-Editor;
  }

  @media (min-width: $large) {
    margin: 0 auto;
    width: 100%;
    max-width: 1170px;
    padding: 0;
    gap: 50px;
  }
}

%GridAligns {
  // Alinhamento Vertical
  &.v-top {
    align-items: flex-start;
  }

  &.v-center {
    align-items: center;
  }

  &.v-bottom {
    align-items: flex-end;
  }

  // Alinhamento Horizontal
  &.h-start {
    justify-items: flex-start;
  }

  &.h-center {
    justify-items: center;

    > div,
    > section {
      &:first-child {
        margin-left: auto;
      }

      &:last-child {
        margin-right: auto;
      }
    }
  }

  &.h-between {
    justify-content: space-between;
  }

  &.h-end {
    justify-items: flex-end;
  }
}

%WP-Editor {
  > .block-editor-inner-blocks {
    width: 100%;

    > .block-editor-block-list__layout {
      display: flex;
      justify-items: flex-start;
      overflow: auto;
    }
  }

  // Alinhamento Vertical
  &.v-top {
    > .block-editor-inner-blocks > .block-editor-block-list__layout {
      align-items: flex-start;
    }
  }

  &.v-center {
    > .block-editor-inner-blocks > .block-editor-block-list__layout {
      align-items: center;
    }
  }

  &.v-bottom {
    > .block-editor-inner-blocks > .block-editor-block-list__layout {
      align-items: flex-end;
    }
  }

  // Alinhamento Horizontal
  &.h-start {
    > .block-editor-inner-blocks > .block-editor-block-list__layout {
      justify-items: flex-start;
    }
  }

  &.h-center {
    > .block-editor-inner-blocks > .block-editor-block-list__layout {
      justify-items: center;

      > div,
      > section {
        &:first-child {
          margin-left: auto;
        }

        &:last-child {
          margin-right: auto;
        }
      }
    }
  }

  &.h-between {
    > .block-editor-inner-blocks > .block-editor-block-list__layout {
      justify-content: space-between;
    }
  }

  &.h-end {
    > .block-editor-inner-blocks > .block-editor-block-list__layout {
      justify-items: flex-end;
    }
  }

  @for $size from 1 through 12 {
    &.size-#{$size} {
      > .block-editor-inner-blocks > .block-editor-block-list__layout {
        > div {
          margin: 0;
          width: calc(100% / $size);
          min-width: fit-content;
        }
      }
    }
  }
}

[data-type='app/grid-sumicity'] {
  &.is-selected {
    .grid-sumicity {
      .h-center {
        > .block-editor-inner-blocks > .block-editor-block-list__layout {
          > div,
          > section {
            &:nth-last-child(2) {
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}
