.TabsCompany {
  overflow: hidden;

  &__tabs {
    overflow: hidden;
    margin-bottom: 24px;
  }

  &__title {
    font-size: $size-24;
    text-align: start;
    color: $primaryDark;
    line-height: 28px;
  }

  &__link {
    background-color: inherit;
    font-size: $size-15;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px 45px;
  }

  &__link.active {
    color: $primaryDark;
    font-weight: 700;
    border-bottom: 2px solid $primaryDark;
  }

  &__content {
    padding: 24px 16px 20px 17px;
    height: 100%;

    &-list {
      margin-top: 16px;
    }
  }

  &__cardTitle {
    font-size: $size-24;
    min-height: 55px;
    line-height: 26px;
    font-weight: 600;
    margin: 0;
  }

  &__checklist {
    font-weight: 500;
    font-size: $size-11;
  }

  .request-proposal {
    padding: 16px 18px;
    font-size: $size-14;
  }

  &__cardDescription {
    font-size: $size-12;
    color: #000;
    height: 70px;
  }

  &__container {
    display: none;
    padding: 26px 0 0;

    &-content {
      display: flex;
      justify-content: space-around;
    }
  }

  &__editor {
    display: block;
    min-height: 100px;
  }

  &-wrap .swiper-wrapper {
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;
  }

  .swiperPlansCompaning {
    max-width: 100%;
    width: auto;
    position: relative;
  }

  .block-editor-block-list__block {
    margin: 0;
  }

  @media (min-width: 980px) {
    .buttonEdit {
      display: table;
      margin: 0 auto;
    }

    &__tabs {
      display: flex;
      margin: 0 auto;
      max-width: 920px;
      margin-bottom: 1px;
    }

    &__title {
      font-size: $size-32;
      text-align: center;
      margin-bottom: 40px;
    }

    &__content {
      min-width: 301px;
      margin: 0 15px 10px;
      box-sizing: border-box;
    }

    .TabsCompany__content-editPanel {
      min-width: 0;
    }

    .swiperPlansCompaning {
      padding: 20px 0 15px 0;
      min-height: 450px;

      .swiper-wrapper {
        transform: none !important;
        justify-content: center;
        align-items: stretch;
        margin: 0 auto;

        > .block-editor-inner-blocks {
          width: 100%;

          > .block-editor-block-list__layout {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            > .block-editor-block-list__block {
              width: auto;
            }
          }
        }
      }
    }
    .wrapButtons-companing {
      display: none;
    }
  }
}
