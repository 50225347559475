.divider {
  border-color: currentColor;

  &.line {
    @extend %dividerLine;
  }

  &.dotted {
    @extend %dividerDotted;
  }
}

%dividerLine {
  border-style: solid;
  border-bottom: 0;
  background: transparent;
}

%dividerDotted {
  border-style: dotted;
  border-bottom: 0;
  background: transparent;
}
