@font-face {
  font-family: 'icomoon';
  src: url('../../blocks/fonts/icomoon.eot?xhcp1y');

  /* stylelint-disable */
  src: url('../../blocks/fonts/icomoon.eot?xhcp1y#iefix')
      format('embedded-opentype'),
    url('../../blocks/fonts/icomoon.ttf?xhcp1y') format('truetype'),
    url('../../blocks/fonts/icomoon.woff?xhcp1y') format('woff'),
    url('../../blocks/fonts/icomoon.svg?xhcp1y#icomoon') format('svg');
  /* stylelint-enable */

  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-social-linkedin:before {
  content: '\e907';
}
.icon-phone:before {
  content: '\e905';
}
.icon-search:before {
  content: '\e900';
}
.icon-keyboard_arrow_down:before {
  content: '\e901';
}
.icon-clear:before {
  content: '\e903';
}
.icon-close:before {
  content: '\e903';
}
.icon-check:before {
  content: '\e908';
}
.icon-location-pin:before {
  content: '\e902';
}
.icon-menu:before {
  content: '\e904';
}
.icon-phone1:before {
  content: '\e942';
}
.icon-facebook:before {
  content: '\ea90';
}
.icon-instagram:before {
  content: '\ea92';
}
.icon-twitter:before {
  content: '\ea96';
}
.icon-youtube:before {
  content: '\ea9d';
}
.icon-whatsapp:before {
  content: '\e906';
}
