.Container {
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 20px;

  &.flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &-small {
    max-width: 1084px;
  }

  @media (min-width: $laptop) {
    &.flex-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .block-editor-block-list__layout {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      div {
        width: 98%;
      }
    }
  }

  @media (min-width: $large) {
    width: 90%;
    padding: 0;
  }

  @media (max-width: $large) {
    & &-PME {
      padding: 0;
    }
  }
}

// Bloco de container criado pelo Painel
[data-type='base/container-sumicity'] {
  min-height: 100px;
}

.Container-block {
  position: relative;
  z-index: 1;

  &-upper {
    z-index: 2;
  }

  //Padding
  &-pd-small {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @media (min-width: $large) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  &-pd-medium {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media (min-width: $large) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &-pd-large {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media (min-width: $large) {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
    }
  }
}
