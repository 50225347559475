.ContactUsCards {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    width: calc(25% - 80px);
    min-width: 208px;
    border-radius: 10px;
    background: $greyLighten;
    padding: 16px 16px 8px;
    display: flex;
    justify-self: flex-start;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 16px;

      @media (min-width: $mobile-large) {
        margin-bottom: 0;
        margin-right: 40px;
      }

      @media (min-width: $large) {
        margin-right: 80px;
      }
    }

    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }

    &-icon {
      margin: 0;
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        display: block;
      }
    }

    &-title {
      padding-left: 7px;
      text-align: center;
      margin: 0;
      color: $primaryAnil;
      font-weight: 400;
      font-size: $size-16;
      line-height: $line-26;
      width: calc(100% - 40px);

      &.full {
        width: 100%;
      }
    }

    &-text {
      width: 100%;
      text-align: left;
      margin: 0;
      color: $grey;
      font-weight: 500;
      font-size: $size-14;
      line-height: $line-20;
    }

    &-button {
      width: 100%;
      text-align: center;
      margin: 8px 0 0;
      padding: 8px;
      background: transparent;
      border: none;
      cursor: pointer;
      color: $primaryAnil;
      font-weight: 500;
      font-size: $size-14;
      line-height: $line-20;

      &:hover {
        color: $primaryLight;
      }
    }
  }

  // WP Editor
  @extend %ContactUsCardsEditor;

  @media (min-width: $mobile-large) {
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    overflow: auto;
    margin: 0 -20px;
    padding: 0 20px;
    padding-bottom: 20px;
  }
}

// WP Editor
%ContactUsCardsEditor {
  .block-editor-inner-blocks {
    width: 100%;

    .block-editor-block-list__layout {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
    }

    .wp-block {
      display: flex;
      max-width: 250px;
      margin: 0;

      &.is-selected {
        .ContactUsCards__item {
          background: $greyLight;
        }
      }
    }
  }
}
