.SolutionSection {
  position: relative;
  overflow: hidden;

  &-excerpt {
    display: none;
  }

  &-swipper {
    max-width: 300px;
  }

  &-accordion {
    &-item {
      box-shadow: 2px -2px 8px rgba(0, 0, 0, 0.1),
        -2px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      height: 360px;
      margin-bottom: 10px;
      margin-right: 22px !important;
      max-width: 300px;

      &-image {
        display: block;
        min-height: 200px;
        width: 100%;

        img {
          margin: 0;
        }
      }

      &-content {
        padding: 10px 20px;

        h3 {
          color: $primaryAnil;
        }

        p {
          word-break: break-word;
        }
      }
    }
  }

  &-wrapButtons {
    display: flex;
    gap: 16px;
  }

  &-prev,
  &-next {
    position: static;
    width: 55px;
    height: 51px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primaryAnil;
    border-radius: 5px;
    transition: 0.1s;

    &.swiper-button-disabled {
      background: rgba($primaryAnil, 0.58);

      &:hover {
        background: rgba($primaryAnil, 0.58);
        box-shadow: none;

        &:after {
          color: $white;
        }
      }
    }

    &-prev {
      &:after {
        content: '\e901';
        font-size: $size-32;
        transform: rotate(90deg);
        font-family: 'icomoon', sans-serif !important;
      }
    }

    &:hover {
      background: $white;
      background: radial-gradient(
        50% 50% at 50% 50%,
        $primary 0%,
        $primaryDark 100%
      );
    }

    &:after {
      content: '\e901';
      font-size: $size-32;
      transform: rotate(-90deg);
      font-family: 'icomoon', sans-serif !important;
    }
  }

  .block-editor-block-list__layout {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;

    .block-editor-block-list__block {
      width: fit-content;
      margin: 0 !important;

      &:not(:last-child) {
        margin: 0 20px 0 0 !important;
      }
    }
  }

  @media (min-width: $tablet) {
    &-accordion-item {
      margin-left: auto;
    }

    &-swipper {
      min-width: 280px;
      max-width: none;
    }
  }

  @media (min-width: $large) {
    .Container {
      display: flex;
    }

    &-excerpt {
      display: block;
      font-size: $size-16;
      margin-bottom: 30px;
    }

    &-col {
      &:first-child {
        width: 100%;
      }
      &:nth-child(2) {
        width: 100%;
        max-width: 560px;
      }
    }

    &-accordion {
      overflow: hidden;
      max-width: 1020px;
      &-item {
        min-width: 250px;
        margin-left: 20.6px;
        &:nth-child(1) {
          margin-left: auto;
        }
        & &-title {
          cursor: pointer;
        }
      }
    }

    &-swipper {
      min-width: 280px;
      max-width: none;
    }

    &-wrapButtons {
      margin-top: 300px;
    }
  }
}
