.CardsDropdown {
  &-accordion,
  &-normal {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.5s;
    flex-wrap: wrap;

    &-item {
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      margin: 0 auto;

      &:not(:last-child) {
        margin-bottom: 15px;

        @media (min-width: $container) {
          margin: 0;
        }
      }

      & &-content {
        background-color: $whiteLight;
        position: relative;
        z-index: -999;
        display: none;
        border-radius: 8px;
        padding-top: 15px;

        &.wp-edit {
          display: block;
        }

        p {
          padding: 30px 20px 25px;
          overflow-wrap: anywhere;
          letter-spacing: 0.75px;
          box-sizing: border-box;
        }
      }

      &-content-edit {
        display: table;
        margin: 0 auto;
      }

      & &-title {
        display: flex;
        align-items: center;
        gap: 30px;
        margin: 0;
        font-weight: 400;
        position: relative;
        border-radius: 15px;
        background-size: cover;
        z-index: 999;
        transition: 0.5s;
        background-image: linear-gradient(45deg, $primaryDark, transparent);
        padding-right: 17%;
        padding-left: 7%;
        height: 116px;
        letter-spacing: 0.75px;
        color: $white;
        font-size: $size-16;

        .CardsDropdown-accordion-item-title-text {
          display: flex;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          -moz-box-orient: vertical;
          word-wrap: break-word;
          line-height: 1.2;
          max-height: 7.2rem;
          min-height: 7.2rem;
          margin: 0;
        }
      }

      .CardsDropdown-accordion-item-icons {
        display: flex;
        align-items: center;
        min-height: 7.2rem;
        max-height: 7.2rem;
      }

      .icon {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        font-size: $size-28;
        right: 20px;
        transform: translateY(-50%);
        transition: 0.5s;
      }

      &--isActive {
        .CardsDropdown-accordion-item-content {
          display: block;
          transform: translate(0px, -27px);
          border-radius: 0 0 8px 8px;
          transition: 0.5s;
        }

        .icon {
          top: 50%;
          transform: rotate(-180deg) translateY(50%);
        }
      }
    }
  }

  .block-editor-inner-blocks {
    width: 100%;

    .block-editor-block-list__layout {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      transition: all 0.5s;
      flex-wrap: wrap;

      .block-editor-block-list__block {
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin: 0 auto;

        .CardsDropdown-accordion-item-content {
          .block-editor-rich-text__editable {
            background-color: $whiteLight;
            position: relative;
            border-radius: 8px;
            padding: 30px 20px 25px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  //Variantes
  &.large-itens {
    @extend %variant-1;
  }

  &.medium-itens {
    @extend %variant-2;
  }

  @media (min-width: $large) {
    &-accordion {
      gap: unset;

      &-item {
        & &-title {
          cursor: pointer;
        }
      }
    }

    .Container {
      display: flex;
      gap: unset;
    }

    &-excerpt {
      display: block;
    }

    & &-col {
      &:first-child {
        width: 100%;
      }

      &:nth-child(2) {
        width: 100%;
        max-width: 560px;
      }
    }
  }

  @media (min-width: $container) {
    &-accordion {
      &-item {
        margin: 0;
      }
    }
  }
}

// com 3 items
%variant-1 {
  .CardsDropdown-accordion {
    justify-content: space-between;

    .CardsDropdown-normal-item,
    .CardsDropdown-accordion-item,
    .block-editor-block-list__block {
      min-width: 328px;
      width: 328px;
    }
  }
}

// com 4 items
%variant-2 {
  .CardsDropdown-accordion {
    gap: unset;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 0 20px;
    margin: 0 -20px;
    width: 100vw;

    .CardsDropdown-normal-item,
    .CardsDropdown-accordion-item,
    .block-editor-block-list__block {
      min-width: 251px;
      width: 24%;
      background-size: cover;
      border-radius: 15px;
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &-title {
        height: 124px;
        flex-direction: row;
        flex-wrap: wrap;
        gap: unset;
        letter-spacing: normal;
        align-content: center;
        padding: 10px;

        .caption {
          margin-bottom: $marginExtraSmall;
        }

        .text {
          font-weight: 500;
          line-height: normal;
        }
      }

      @media (min-width: $large) {
        margin: 0;
      }
    }

    @media (min-width: $large) {
      width: 100%;
      flex-wrap: nowrap;
      padding: 0;
      margin: 0;

      .block-editor-block-list__layout {
        flex-wrap: nowrap;
      }
    }
  }
}
