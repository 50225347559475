// ** Colors
// =================
$whiteExtraLight: #fafafa;
$whiteLighten: #fbfbfb;
$whiteLight: #f8f8f8;
$white: #fff;
$black: #000;
$black-opacity: rgba(0, 0, 0, 0.1);

$primaryLight: #0069c3;
$primaryAnil: #0050b4;
$primary: #0048a2;
$primaryDark: #000b6b;
$primaryDarker: #041845;
$primaryNavy: #014da7;

$secondaryLighten: #e58c2c;
$secondaryLigh: #f07d00;
$secondary: #d87100;
$secondaryDark: #c06500;

$tertiary: #9db707;
$tertiaryLight: #b3cb29;
$tertiaryMedium: #afcb08;
$tertiaryDark: #899e07;

$fourth: #63115c;
$fourthMedium: #450b50;
$fourthDark: #2d0544;

$greyLighten: #f2f2f2;
$greyMidleLight: #eaeaea;
$greyLight: #d3d3d3;
$greySoft: #cecece;
$greySoftMedium: #acacac;
$greySoftDark: #898787;
$grey: #424242;
$greyMedium: #6f6868;
$greyDark: #5e5e5e;
$greyDarken: #393939;

$greySecondary: #b0aeae;

// ** Fonts
// =================
$poppins: 'Poppins', sans-serif;
// font-weight -> Regular 400, Medium 500 // Bold 700

//Tamanhos para font-size

$size-8: 0.5rem; // 8px
$size-10: 0.625rem; //10px
$size-12: 0.75rem; // 12px
$size-14: 0.875rem; // 14px
$size-16: 1rem; // 16px
$size-18: 1.125rem; // 18px
$size-20: 1.25rem; // 20px
$size-22: 1.375rem; // 22px
$size-24: 1.5rem; // 24px
$size-26: 1.625rem; //26px
$size-28: 1.75rem; // 28px
$size-32: 2rem; // 32px
$size-40: 2.5rem; // 40px
$size-48: 3rem; // 48px
$size-54: 3.375rem; // 54px
$size-56: 3.5rem; // 56px

//Tamanhos para linh-height
$line-20: 1.25rem; // 20px
$line-24: 1.5rem; // 24px
$line-28: 1.75rem; // 28px
$line-36: 2.25rem; // 36px
$line-42: 2.625rem; // 42px
$line-48: 3rem; // 48px
$line-56: 3.5rem; // 56px
$line-58: 3.625rem; // 58px
$line-66: 4.125rem; // 66px
$line-72: 4.5rem; // 72px
$line-84: 5.25rem; // 84px
$line-96: 6rem; // 96px

// ** Font-Sizes - Legado (atualizado para rem)
$size-11: 0.6875rem; // 11px
$size-15: 0.9375rem; // 15px
$size-23: 1.4375rem; // 23px
$size-26: 1.625rem; // 24px
$size-28: 1.75rem; // 28px
$size-42: 2.625rem; // 42px
$size-30: 1.875rem; // 30px
$size-33: 2.0625rem; // 33px
$size-44: 2.75rem; // 44px
$line-18: 1.125rem; // 18px
$line-22: 1.375rem; // 22px
$line-26: 1.625rem; // 26px
$line-30: 1.875rem; // 30px

// ** Margins
// =================
$marginNano: 0.125rem; //2px
$marginMicro: 0.25rem; //4px
$marginExtraSmallMedium: 0.375rem; //6px
$marginExtraSmall: 0.5rem; //8px
$marginSmall: 0.75rem; //12px
$marginBase: 1rem; //16px
$marginMedium: 1.5rem; //24px
$marginLarge: 2rem; //32px
$marginExtraLarge: 2.5rem; //42px
$marginHuge: 4rem; //64px
$marginExtraHuge: 6rem; //96px
$marginSuperHuge: 8.125; //130px

// ** Breakpoints
// =================
$extraHude: 1850px;
$extraLarge: 1300px;
$container: 1170px;
$large: 980px;
$laptop: 1024px;
$tablet: 768px;
$mobile-large: 425px;
$mobile: 360px;
