@import '../mixins/ImageWithTopics';

.ImageWithTopics {
  &.horizontal {
    @extend %ImageWithTopicsHorizontal;
  }

  &.vertical {
    @extend %ImageWithTopicsVertical;
  }

  @extend %WithTopicsWPPanel;
}

%ImageWithTopicsHorizontal {
  padding: 24px 0 38px;

  .ImageWithTopics {
    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      max-width: 500px;
      margin: 0 auto;

      .only-text {
        order: 1;
      }

      .TopicItem {
        order: 3;
      }

      @include ImageWithTopicsIMG(21%);

      @media (max-width: ($large - 1px)) {
        .TopicItem {
          &.margin-none:not(:last-child) {
            margin-bottom: $marginMedium;
          }
        }
      }

      @media (min-width: $large) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 44px 0 49px;
        max-width: none;

        .only-text {
          width: 100%;
        }

        .TopicItem {
          width: 33.333%;
          padding-right: 50px;

          .subtitle {
            font-size: $size-16;
            line-height: $line-24;
          }

          .text {
            font-size: $size-14;
            line-height: $line-24;
          }
        }

        &.left {
          padding-right: calc(21% + 44px);
        }

        &.right {
          padding-left: calc(21% + 44px);
        }
      }
    }
  }
}

%ImageWithTopicsVertical {
  .ImageWithTopics {
    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      position: relative;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;

      .heading-block {
        order: 1;
      }

      .TopicItem__title {
        margin-bottom: 0;
      }

      p {
        margin-top: 5px;
      }

      > .heading-block:first-of-type {
        padding-top: 20px;
      }

      .paragraph-block,
      .TopicItem {
        order: 3;
      }

      .Button {
        order: 3;
      }

      &.title-after {
        .heading-block {
          order: 2;
        }

        figure {
          margin-top: 50px;
        }

        > .heading-block:first-of-type {
          padding-top: unset;
        }
      }

      @include ImageWithTopicsIMG(35%);

      @media (min-width: $large) {
        width: 100%;
        position: relative;
        max-width: unset;

        &.left {
          padding-right: calc(35% + 47px);
        }

        &.right {
          padding-left: calc(35% + 47px);
        }

        .paragraph-block,
        .TopicItem {
          max-width: unset;
        }

        > .heading-block:first-of-type {
          padding-top: unset;
        }

        &.title-full {
          > .imageTop {
            margin-top: 80px;
          }

          > .heading-block:first-of-type {
            position: absolute;
            left: 0;
            order: 0;
          }

          > :nth-child(3) {
            margin-top: 80px;
          }
        }
      }
    }
  }

  @media (min-width: $large) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

// Estilos Para o painel do Wordpress
%WithTopicsWPPanel {
  &.wp-editor {
    &.horizontal {
      .ImageWithTopics {
        &__content {
          .block-editor-block-list__layout {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;

            > .wp-block {
              margin: 0;
              width: 33.333%;

              &[data-type='base/heading-sumicity'],
              &[data-type='base/paragraph-sumicity'] {
                width: 100%;
              }

              .TopicItem {
                width: 100%;
                padding-right: 50px;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }

    .ImageWithTopics {
      &__content {
        &--img {
          &.with-figcaption {
            justify-content: start;

            img {
              max-height: 80%;
            }

            figcaption {
              display: block !important;
            }
          }
        }
      }
    }

    //panel style
    .block-list-appender .wp-block {
      padding-top: 25px;
    }
  }
}
