.paragraph-block {
  .only-text__content {
    li a {
      color: $grey !important;
    }
  }

  &.removeMarker {
    .only-text__content {
      ul {
        padding-left: 0;
      }

      li {
        list-style-type: none;
      }
    }
  }

  .fw-500 {
    font-weight: 500 !important;
  }

  .block-list-appender > .block-editor-inserter {
    display: block;
    position: absolute;
  }

  .block-editor-block-list__block .block-list-appender {
    position: unset !important;
  }
}
