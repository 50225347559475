@mixin ScrollBar(
  $width: 5px,
  $bgThumb: $greySecondary,
  $bg: transparent,
  $radius: 10px
) {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: $bg;
    border-radius: $radius;
    overflow: hidden;
    padding: 20px 0;
  }

  &::-webkit-scrollbar {
    width: $width;
    background-color: $bg;
    border-radius: $radius;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bgThumb;
    border-radius: 10px;
  }
}
