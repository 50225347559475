/* stylelint-disable */
.shortForm {
  overflow: hidden;
  background-color: $primaryAnil;
  position: relative;
  padding: 30px 0;

  a {
    color: inherit;
    text-decoration: none;
  }

  &__content {
    width: 100%;
    margin-bottom: $marginExtraLarge;
  }

  &-input {
    margin-bottom: 30px;
    height: 50px;
    width: 100%;
    border-radius: 10px !important;
    border: 2px solid $primaryAnil !important;
  }

  &-button {
    height: 50px;
    width: 100%;
    border-radius: 26px;
    background-color: $primaryAnil;
    border: 2px solid $white;
    color: $white;
    font-weight: 600;
  }

  @media (min-width: $tablet) {
    .Container {
      padding: 0 50px;
    }
  }

  @media (max-width: ($mobile-large + 114px)) {
  }

  @media (min-width: $large) {
    padding: 25px 0 30px;

    .Container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      &.top {
        align-items: flex-start;
      }

      &.bottom {
        align-items: flex-end;
      }
    }

    &__content {
      width: 100%;
      max-width: 420px;
      margin-right: 50px;
      margin-bottom: 0;

      .Typography.title-6 {
        font-weight: 400;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      min-width: 650px;
    }

    &-input {
      margin: 10px 0;
      height: 50px;
      color: #818181;

      &:nth-child(4) {
        max-width: none;
        margin-bottom: 25px;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 12px;
        color: #818181;
      }
    }

    &-inputSelected {
      padding: 0 8px;
    }

    &-button {
      cursor: pointer;
      max-width: 300px;
      margin: 0 auto;

      &:hover {
        color: $primaryAnil;
        background-color: $white;
      }
    }
  }
}
