.Accordion {
  transition: 0.5s;
  box-sizing: border-box;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &--isActive {
    display: block;
  }

  &--isInactive {
    display: none;
  }

  summary .Accordion__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
  }

  summary::marker {
    content: '';
  }

  &__marker {
    border: unset;
    right: 10px;
    font-size: 40px;

    &.default {
      display: flex;
      justify-self: center;
      align-items: center;
    }

    &.blue,
    &.bordered,
    &.withDescription {
      &::after {
        content: '+';
      }
    }
  }

  &__title {
    width: 100%;
    color: $greyDarken;
    margin: 0;
    padding-right: 15px;
    font-size: $size-12;
    line-height: $line-22;
    font-weight: 700;

    @media (min-width: $tablet) {
      font-size: $size-20;
      line-height: $line-30;
    }
  }

  > summary h3 {
    font-weight: 600;

    @media (min-width: $tablet) {
      font-size: $size-16;
      line-height: $line-24;
    }
  }

  &.default {
    @extend %AccordionDefault;
  }

  &.blue {
    @extend %AccordionBlue;
  }

  &.bordered {
    @extend %AccordionBordered;
  }

  &.withDescription {
    @extend %AccordionDescription;
  }

  &[open] > summary > .Accordion__heading > .Accordion__marker {
    &.default {
      transform: rotate(180deg);
    }
    &.blue,
    &.bordered,
    &.withDescription {
      &::after {
        content: '-';
      }
    }
  }

  &[open] > summary ~ * {
    animation: sweep 0.5s ease-in-out;
  }

  &[close] > summary ~ * {
    animation: sweepClose 0.5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      transform: translateX(-10px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes sweepClose {
    0% {
      opacity: 0;
      transform: translateX(0px);
    }

    100% {
      opacity: 1;
      transform: translateX(-10px);
    }
  }
}

%AccordionDefault {
  border-bottom: 2px solid $greyLighten;

  &.borderBefore {
    border-bottom: unset;

    summary {
      border-bottom: 2px solid $greyLighten;
    }

    > .Accordion__content {
      &.no-padding {
        padding-top: 20px;

        @media (min-width: $tablet) {
          padding-top: 40px;
        }
      }
    }
  }

  &:not(.borderBefore) {
    > .Accordion__content {
      &.no-padding {
        padding-bottom: 20px;

        @media (min-width: $tablet) {
          padding-bottom: 40px;
        }
      }
    }
  }

  > summary .Accordion__heading {
    padding: 24px 23px;

    &.noPadding {
      padding: 24px 1px;
    }

    &.textGrey {
      color: $greyDarken;

      .Accordion {
        &__title {
          color: $greyDarken;
        }

        &__marker svg path {
          fill: $greyDarken;
        }
      }
    }

    &.textBlack {
      color: $black;

      .Accordion {
        &__title {
          color: $black;
        }

        &__marker svg path {
          fill: $black;
        }
      }
    }

    &.textBlueAnil {
      color: $primaryAnil;

      .Accordion {
        &__title {
          color: $primaryAnil;
        }
      }
    }

    &.textWhite {
      color: $white;

      .Accordion {
        &__title {
          color: $white;
        }

        &__marker svg path {
          fill: $white;
        }
      }
    }

    @media (min-width: $tablet) {
      padding: 32px 150px 40px 40px;

      &.noPadding {
        padding: 32px 1px 40px 1px;
      }
    }
  }

  > .Accordion__content {
    &:not(.no-padding) {
      padding: 0 24px 21px;

      @media (min-width: $tablet) {
        padding: 0 150px 40px 40px;
      }
    }
  }
}

%AccordionBlue {
  > summary .Accordion__heading {
    background-color: $primary;
    border-radius: 24px;
    color: $white;
    border-bottom: unset;
    padding: 21px 24px;

    .Accordion__title:not(h3) {
      color: $white;
    }

    h3 {
      color: $white;
    }

    /* stylelint-disable */
    > .Accordion__marker {
      color: $white;
      font-weight: 400;
    }
    /* stylelint-enable */

    @media (min-width: $tablet) {
      padding: 11px 94px 11px 20px;
      border-radius: 19px;
    }
  }

  > .Accordion__content {
    &.no-padding {
      padding-top: 20px;

      @media (min-width: $tablet) {
        padding-top: 40px;
      }
    }

    &:not(.no-padding) {
      padding: 21px 24px;

      @media (min-width: $tablet) {
        padding: 30px 20px 20px;
      }
    }
  }
}

%AccordionBordered {
  > summary .Accordion__heading {
    width: 100%;
    border-bottom: 2px solid $greyLighten;
    padding: 21px 24px;

    > .Accordion__title:not(h3) {
      color: $primary;
    }

    > h3 {
      color: $primaryAnil;
    }

    /* stylelint-disable */
    > .Accordion__marker {
      color: $black;
      font-weight: 400;
    }
    /* stylelint-enable */

    @media (min-width: $tablet) {
      padding: 10px 94px 37px 20px;
    }
  }

  > .Accordion__content {
    &.no-padding {
      padding-top: 20px;

      @media (min-width: $tablet) {
        padding-top: 40px;
      }
    }

    &:not(.no-padding) {
      padding: 21px 24px;

      @media (min-width: $tablet) {
        padding: 40px 40px 20px;
      }
    }
  }
}

%AccordionDescription {
  > summary {
    border: solid 2px;
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 20px;
    position: relative;

    .Accordion__heading {
      margin: 0;
      position: unset;

      > .Accordion__marker {
        font-weight: 400;
        position: absolute;
        height: 16px;
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto;
      }
    }

    .Accordion__title {
      color: unset;
    }

    .Accordion__subtitle {
      margin: 0;
      max-width: 85%;
      font-size: $size-14;
    }

    &.borderBlue {
      border: solid $primaryLight 2px;

      .Accordion__heading {
        color: $primaryLight;
      }
    }

    &.borderOrange {
      border: solid $secondaryLighten 2px;

      .Accordion__heading {
        color: $secondaryLighten;
      }
    }

    &.borderGreen {
      border: solid $tertiary 2px;

      .Accordion__heading {
        color: $tertiary;
      }
    }
  }
}

// WP Editor
.block-editor-inner-blocks {
  .Accordion {
    background: $greyMidleLight;
    padding: 20px 10px;
    margin-bottom: 40px;

    summary {
      margin-bottom: 40px;
    }
  }
}

.block-editor-block-list__block {
  .Accordion {
    margin: 0;

    summary {
      margin: 0;
    }

    .wp-block {
      margin: 0;
    }
  }

  .AccordionContainer {
    .block-editor-inner-blocks {
      .block-editor-block-list__layout {
        position: relative;
        padding-bottom: 20px;
      }
    }
  }
}
