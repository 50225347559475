@import '../molecules/ContactUsCards';

.contactUs {
  background: $white;
  text-align: center;

  .Typography {
    text-align: center;

    @media (min-width: $mobile-large) {
      text-align: left;
    }
  }

  @media (min-width: $mobile-large) {
    text-align: left;
  }
}
