@import '../molecules/CardSelfService';

.SelfService {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 30px;

    .block-editor-block-list__layout {
      display: flex;
      gap: 10px;
    }

    @media (min-width: $tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: center;
    }
  }
}
