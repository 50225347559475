.InfoBox {
  font-family: $poppins;

  .has-vivid-cyan-blue-color {
    text-decoration: underline;
  }

  p {
    line-height: 26px;
    font-size: $size-12;
  }

  &-title {
    font-weight: normal;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid currentColor;
    }

    &:not(:empty)::before {
      margin-right: 0.25em;
    }

    &:not(:empty)::after {
      margin-left: 0.25em;
    }

    .Typography {
      display: inline-block;
      width: fit-content;
      padding: 0 10px;
    }
  }

  @media (min-width: $large) {
    & &-title {
      font-size: $size-16;
    }

    p {
      font-size: $size-14;
    }
  }
}
