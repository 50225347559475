.PlansDetailsGridPlans {
  .plansContainer {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;

    .contentContainer {
      img{
        height: 40px;
        width: auto;
      }

      h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      ul {
        font-size: 14px;
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }

    .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 60px;
        width: 100%;
        max-width: 470px;
        height: auto;
      }
    }
  }
}

@media (min-width: $laptop) {
  .PlansDetailsGridPlans {
    .plansContainer {
      flex-direction: row;
      column-gap: 40px;

      .contentContainer {
        width: 100%;

        h2 {
          font-size: 32px;
          line-height: 150%;
        }
      }
      .imageContainer{
        width: 530px;

        img{
          height: 350px;
          width: 530px;
          object-fit: cover;
        }
      }
    }
  }
}
