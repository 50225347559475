.Hero {
  overflow: hidden;

  &.twoImages {
    @extend %TwoImages;
  }

  &.allImage {
    @extend %AllImage;
  }

  &.hasPlans {
    @extend %PlanOnHero;
  }

  & &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;

    .max-medium {
      max-width: 630px;
    }

    &-left {
      flex-direction: row;
    }

    &-right {
      flex-direction: row-reverse;
    }

    @media (min-width: $tablet) {
      min-height: 375px;
    }
  }

  &--content {
    width: 100%;
    padding: 56px 0;
    z-index: 2;

    @media (min-width: $tablet) {
      padding: 65px 0;
    }
  }

  &--imageWrapper {
    display: none;
    &-top,
    &-bottom {
      position: absolute;
    }

    &-top {
      top: 0;
    }

    &-bottom {
      bottom: 0;
    }

    &-left {
      right: 0;
    }

    &-right {
      left: 0;
    }

    @media (min-width: $large) {
      display: flex;
      max-width: calc(100% - 500px);
      overflow: hidden;
      justify-content: right;
    }

    @media (min-width: $extraLarge) {
      img:first-child {
        display: block;
      }
    }
  }

  @extend %WpPanel;
}

%TwoImages {
  .Hero--imageWrapper {
    align-items: center;

    &-top {
      align-items: flex-start;
    }

    &-bottom {
      align-items: flex-end;
    }

    img:first-child {
      display: none;
    }

    @media (min-width: $tablet) {
      display: flex;
    }

    @media (min-width: $extraLarge) {
      img {
        width: 50%;
      }

      img:first-child {
        display: block;
      }
    }
  }
}

%AllImage {
  position: relative;

  .Hero__wrapper {
    position: unset;
  }

  .Hero--content {
    z-index: 2;
  }

  .Hero--imageWrapper {
    @media (min-width: $large) {
      display: block;

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right;
      }
    }
  }

  @media (min-width: $large) {
    min-height: 375px;
  }
}

// Ajustes para o card de planos quando adicionado ao Hero
%PlanOnHero {
  .Hero--content {
    padding-bottom: 0;
  }

  .Plans {
    margin-top: 30px;
    margin-bottom: 0px;

    .swiper-wrapper {
      margin-bottom: 10px;

      .swiper-slide {
        min-width: fit-content;
      }
    }

    .CardPlan-title {
      @media (min-width: $mobile-large) {
        display: flex;
        gap: 15px;
        align-items: baseline;
      }
    }

    .CardPlan-price {
      text-align: center;
      border-bottom: none;
    }

    //disable
    .swiper-slide:not(.planHas),
    .Typography,
    .CardPlan-list,
    .CardPlan-offerInfo,
    .CardPlan-label {
      display: none;
    }

    @media (min-width: $tablet) {
      display: none;
    }
  }

  .Plans-wrapButtons {
    display: none;
  }

  @media (max-width: ($tablet - 1px)) {
    background: linear-gradient(currentColor 70%, #fff 70%);
  }

  @media (min-width: $tablet) {
    .Hero--content {
      padding: 65px 0;
    }
  }
}

// Ajustes para o Painel do WP
%WpPanel {
  .block-editor-block-list__block {
    margin: 0;
  }

  .block-editor-inner-blocks {
    position: relative;
  }

  .wp-block .Plans {
    display: block;
  }
}

.block-editor-block-list__block {
  .Hero--imageWrapper {
    img {
      max-height: 400px;
    }
  }
}
