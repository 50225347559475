.HeroLp {
  position: relative;

  &-image {
    &_mobile {
      width: 100%;
      border-bottom: 100px #050122 solid;
      margin-bottom: 570px;
    }
  }

  &-container {
    position: absolute;
    top: 180px;
    z-index: 1;
  }

  &-forms {
    border: 1px solid rgba(0, 0, 65, 0.18);
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 25px 20px;
    background-color: $white;
    width: calc(100% - 20px);
  }

  &-title {
    font-size: $size-28;
    color: $primaryDark;
    line-height: 42px;
    font-weight: 700;
    margin: 0;

    &_bottom {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $primaryDark;

    &_bottom {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
    }
  }

  &-input {
    width: 100%;
    height: 40px;
    left: 0px;
    top: 56px;
    border: 1px solid #b0b0b0 !important;
    margin-bottom: 15px;
    color: #818181;

    &::placeholder {
      font-weight: 400;
      font-size: 12px;
      color: #818181;
    }
  }

  &-inputSelected {
    padding: 0 8px;
    color: #818181;
  }

  &-button {
    width: 100%;
    height: 40px;
    background: $secondaryLigh;
    border-radius: 4px;
    border: 0;
    color: $white;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
  }

  @media (min-width: 400px) {
    &-container {
      top: 220px;
    }
  }

  @media (min-width: 500px) {
    &-container {
      top: 280px;
    }
  }

  @media (min-width: 600px) {
    &-container {
      top: 320px;
    }
  }

  @media (min-width: 750px) {
    &-container {
      top: 400px;
      left: 0;
      width: 100%;
    }

    &-forms {
      width: 665px;
      margin: 0 auto;
    }
  }

  @media (min-width: 900px) {
    height: 700px;
    background: radial-gradient(
      109.24% 109.24% at 67.6% -6.05%,
      #000000 33.33%,
      #000000 46.86%,
      #020234 60.99%,
      #090928 77.62%,
      #0a0c24 90.08%
    );

    &-image {
      &_mobile {
        height: 70%;
        width: 100%;
        border-bottom: unset;
        margin-bottom: 0;
      }
    }

    figure:nth-child(1) {
      height: 100%;
    }

    &-container {
      top: 30px;
      width: auto;
      right: 5%;
      left: unset;
    }

    &-forms {
      width: 365px;
      margin: 0;
    }
  }

  @media (min-width: 1100px) {
    &-forms {
      width: 400px;
      margin: 0;
    }

    &-image {
      &_mobile {
        height: 65%;
      }
    }
  }

  @media (min-width: 1280px) {
    &-forms {
      width: 470px;
      margin: 0;
    }

    &-image {
      &_mobile {
        height: 75%;
      }
    }
  }

  @media (min-width: 1350px) {
    height: 680px;
    margin-bottom: 40px;

    &-forms {
      margin: 0;
      width: 465px;
    }

    &-image {
      &_mobile {
        height: 90%;
      }
    }

    &-container {
      top: 50px;
      right: 5%;
      width: auto;
    }
  }

  @media (min-width: 1400px) {
    &-container {
      top: 50px;
      right: 10%;
    }
  }

  @media (min-width: 1450px) {
    &-image {
      &_mobile {
        height: 100%;
      }
    }
  }
}