.CardAdvantage {
  border: 1px solid #d3d3d3;
  padding: 8px 14px 19px 19px;
  border-radius: 20px;
  line-height: 130%;
  font-size: $size-14;
  min-height: 150px;
  background: $white;

  &-head {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    margin-top: -30px;
    margin-bottom: 16px;

    @media (max-width: $tablet) {
      margin-top: -25px;
    }
  }

  &-name {
    white-space: nowrap;
    padding-bottom: 5px;
    margin: 0;
    font-size: $size-14;
    font-weight: 600;
    font-style: normal;

    @media (max-width: $tablet) {
      white-space: pre-wrap;
      padding-bottom: 0px;
    }
  }

  &-image {
    box-shadow: 2px 4px 8px 0 rgba(#000, 0.25);
    border-radius: 10px;
    overflow: hidden;
    margin: 0;
    width: 53px;
    min-width: 53px;
    height: 53px;
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;

    img {
      margin: 0;
      display: block;
      border-radius: 10px;
    }
  }

  p {
    margin: 0;
    line-height: 21.6px;
    font-size: $size-12;
    font-weight: 400;
  }

  @media (min-width: $large) {
    max-width: 210px;
    min-width: 210px;
    margin-right: 0 !important;
  }
}
