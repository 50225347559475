// Estilos padrões para a imagem do componente Image With Topics
@mixin ImageWithTopicsIMG($width) {
  .ImageWithTopics {
    &__content {
      &--img {
        order: 2;
        margin: 0 0 $marginMedium;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;

        img {
          border-radius: 40px;
          overflow: hidden;
          object-fit: cover;
        }

        figcaption {
          width: 100%;
          margin: $marginMedium 0 0;
          display: block;
          color: $black;
          text-align: center;

          @media (min-width: $large) {
            display: none;
          }
        }

        @media (min-width: $large) {
          position: absolute;
          top: 0;
          width: $width;
          height: 100%;
          display: flex;

          &.left {
            right: 0;
            justify-content: flex-end;
          }

          &.right {
            left: 0;
          }

          &.imageCenter {
            align-items: center;
          }

          &.imageBottom {
            align-items: flex-end;
          }
        }
      }
    }
  }
}
