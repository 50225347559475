.Button {
  background: radial-gradient(
    50% 50% at 50% 50%,
    $secondaryLighten 0%,
    $secondaryLigh 100%
  );

  border-radius: 27px;
  color: $white;
  font-size: $size-14;
  font-weight: 700;
  padding: 16px 45px;
  display: inline-block;
  text-decoration: none;
  border: none;

  &-center {
    display: table;
    margin: 0 auto;
  }

  &:hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      $secondaryLighten 0%,
      $secondaryDark 100%
    );

    color: $white;
  }

  &.default {
    @extend %ButtonDefault;
  }

  &.default-blue-bold {
    @extend %ButtonBlueBoldDefault;
  }

  &.link {
    @extend %ButtonLink;
  }

  &.CardTv-link {
    @extend %CardTvLink;
  }

  &.withIcon {
    @extend %ButtonWithIcon;
  }

  &.bordered {
    @extend %ButtonBordered;
  }

  &.bordered-white {
    @extend %ButtonBorderedWhite;
  }

  &.blue-gradient {
    @extend %ButtonBlueGradient;
  }

  &.btn-orange {
    @extend %ButtonOrange;
  }

  // Hide on mobile deve ser adicionado em cada variante,
  // de acordo com a variante (flex ou block)
  // &.hide-mobile {
  //   display: none;
  // }

  &.left {
    margin-left: 0;
    margin-right: auto;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
  }

  &.right {
    margin-left: auto;
    margin-right: 0;
    display: block;
    width: fit-content;
  }

  &.left-center {
    margin-left: 0;
    margin-right: auto;

    @media (min-width: $tablet) {
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: fit-content;
    }
  }

  &.center-left {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;

    @media (min-width: $tablet) {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

// Variantes do botão
%ButtonDefault {
  background: transparent;
  font-weight: 400;
  font-size: $size-14;
  color: $primaryAnil;
  display: block;
  width: fit-content;
  padding: 0;

  &.hide-mobile {
    display: none;

    @media (min-width: $large) {
      display: block;
    }
  }

  &.hide-desktop {
    @media (min-width: $large) {
      display: none;
    }
  }

  &.auto-width {
    width: fit-content;
  }

  &:hover {
    background: unset;
    color: unset;
  }

  &:focus {
    box-shadow: unset !important;
  }
}

%ButtonBlueBoldDefault {
  font-size: $size-16;
  line-height: $line-26;
  font-weight: 700;
  color: $primaryDark;
  background: transparent;
  display: block;
  width: fit-content;
  padding: 0;

  &:hover {
    color: $primary;
    background: transparent;
  }

  &.hide-mobile {
    display: none;

    @media (min-width: $large) {
      display: block;
    }
  }

  &.hide-desktop {
    @media (min-width: $large) {
      display: none;
    }
  }
}

%ButtonLink {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: none;

  &.auto-width {
    width: fit-content;
  }

  &:hover {
    background: transparent;
  }

  &.hide-mobile {
    display: none;

    @media (min-width: $large) {
      display: inline-block;
    }
  }

  &.hide-desktop {
    @media (min-width: $large) {
      display: none;
    }
  }
}

%ButtonWithIcon {
  background: $primary;
  color: $white;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  figure {
    margin-left: 10px;
  }

  &.hide-mobile {
    display: none;

    @media (min-width: $large) {
      display: flex;
    }
  }

  &.hide-desktop {
    @media (min-width: $large) {
      display: none;
    }
  }

  &.auto-width {
    width: fit-content;
    min-width: auto;
  }

  &:hover {
    background: $primaryDark;
  }

  &.iconReverse {
    flex-direction: row-reverse;

    figure {
      margin-left: 0;
      margin-right: 30px;
    }
  }

  &.wp-editor {
    figure {
      margin: 0 0 0 30px;
    }

    &.iconReverse {
      figure {
        margin: 0 10px 0 0;
      }
    }
  }

  @media (min-width: $mobile-large) {
    width: 370px;
  }
}

%CardTvLink {
  padding: 0;
  background: none;
  color: $primaryDark;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-size: $size-14;
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    background: none;
    color: $primaryDark;
  }
}

%ButtonBordered {
  background: transparent;
  color: $primaryAnil;
  font-weight: 500;
  font-size: $size-14;
  border: 1px solid $primaryAnil;
  padding: 10px 55px;
  display: block;
  width: fit-content;

  &.hide-mobile {
    display: none;

    @media (min-width: $large) {
      display: block;
    }
  }

  &.hide-desktop {
    @media (min-width: $large) {
      display: none;
    }
  }

  &:hover {
    background: unset;
    color: $black;
    border: 1px solid $black;
  }
}

%ButtonBorderedWhite {
  background: transparent;
  color: $white;
  font-weight: 500;
  font-size: $size-14;
  border: 1px solid $white;
  padding: 10px 55px;
  display: block;
  width: fit-content;

  &.hide-mobile {
    display: none;

    @media (min-width: $large) {
      display: block;
    }
  }

  &.hide-desktop {
    @media (min-width: $large) {
      display: none;
    }
  }

  &:hover {
    background: $white;
    color: $primaryAnil;
  }
}

%ButtonBlueGradient {
  background: radial-gradient(
    50% 50% at 50% 50%,
    $primaryLight 0%,
    $primary 100%
  );

  &:hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      $primaryLight 0%,
      $primaryDark 100%
    );
  }

  &.hide-mobile {
    display: none;

    @media (min-width: $large) {
      display: inline-block;
    }
  }

  &.hide-desktop {
    @media (min-width: $large) {
      display: none;
    }
  }
}

%ButtonOrange {
  &.hide-mobile {
    display: none;

    @media (min-width: $large) {
      display: inline-block;
    }
  }

  &.hide-desktop {
    @media (min-width: $large) {
      display: none;
    }
  }
}
