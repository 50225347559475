.Image {
  display: flex;
  position: relative;
  z-index: 1;
  height: fit-content;
  width: 100%;

  & ~ div {
    position: relative;
    z-index: 2;
  }

  &.image-center {
    justify-content: center;
  }

  &.image-right {
    justify-content: right;
  }

  &.image-left-center {
    @media (min-width: $tablet) {
      justify-content: center;
    }
  }

  &.image-center-left {
    justify-content: center;

    @media (min-width: $tablet) {
      justify-content: flex-start;
    }
  }

  &.mobile {
    display: none;
  }

  &.absolute-right,
  &.absolute-left,
  &.absolute-top,
  &.absolute-center,
  &.absolute-bottom {
    z-index: -1;
    position: absolute;
    margin: 0;
    min-height: fit-content;
    width: fit-content;

    figure {
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  &.absolute-right {
    right: 0;
  }

  &.absolute-left {
    left: 0;
  }

  &.absolute-top {
    top: 0;
  }

  &.absolute-center {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  &.absolute-bottom {
    bottom: 0;
  }

  @media (min-width: $large) {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: flex;
    }
  }
}

.block-editor-block-list__layout .block-editor-block-list__block:has(.Image) {
  position: unset;
}

[data-type='base/image-sumicity'] {
  min-height: 50px;
  margin: 0;

  &.is-selected {
    background: rgba($greyLight, 0.3);
  }
}

.wp-block .Image {
  &.absolute-right,
  &.absolute-left,
  &.absolute-top,
  &.absolute-center,
  &.absolute-bottom {
    // background-color: rgba($black, 0.1);
    // padding: 20px;
  }
}
