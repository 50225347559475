.MiddleBanner {
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 70px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;

  &-image-background {
    img {
      width: auto;
      float: right;
      margin-bottom: -100px;
    }

    &-desk {
      display: none;
    }

    @media (min-width: $tablet) {
      width: unset;
      display: block;
      z-index: 1;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      img {
        height: 100%;
        object-fit: cover;
        object-position: left center;
        margin-bottom: 0;
      }

      &-full-height {
        height: 100%;
        top: 0;
        transform: translate(0);
      }

      &-desk {
        display: block;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 2;
    position: relative;
    max-width: 763px;

    @media (min-width: $tablet) {
      align-items: flex-start;
      margin-top: unset;
    }
  }

  .Typography {
    text-align: center;

    @media (min-width: $tablet) {
      text-align: left;
    }
  }

  .block-editor-block-list__block {
    margin: 0;

    .Image__content {
      margin: 0;
    }
  }
}
